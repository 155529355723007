import {useCallback} from "react"
import eyeIcon from '../../../../assets/img/auth/eye.svg'
import eyeOpenIcon from '../../../../assets/img/auth/eye-open.svg'


const Input = ({ handleChange, handleBlur, label, labelClassName, inputLimit, error, ...props }) => {

  const handleKeyPress = useCallback((e) => {
    const charCount = e.target.value.length
    if (charCount <= -1) return false
  },[])

  return (
    <div className="form-row">
      {label && <label className= {`label ` + (error && `label-error`)} htmlFor={label}>{label}</label>}
      <div className="input-block">
          <input
            id={label}
            type={props.type}
            className={`form-input ` + (error && `error-border`)}
            onChange={(e) => {
              handleKeyPress(e)
              handleChange(e)
            }}
            onBlur={handleBlur}
            maxLength={inputLimit}
            placeholder={props.placeholder}
            value={props.value}
          />
        <span className={`prefix ` + ((props.name === 'password' || props.name === 'confirm-password') && 'password ')
          + " " + (props.name === 'email' && ' email')
        }/>
        {props.name === 'password' &&
          <img className="password-icon" src={!props.passwordView ? eyeIcon : eyeOpenIcon} alt="icon"
               onClick={()=> props?.setPasswordView(!props?.passwordView)}/>
        }
      </div>
      <div className="characters-left">
        <p className={`error-message ` + (error && `error`)}>{error}</p>
      </div>
    </div>
  )
}

export default Input
