import axios from "axios";

const defaultOptions = {
  baseURL: 'https://cadium.herokuapp.com/',
  headers: {
    'Content-Type': 'application/json',
  },
}

const defaultOptionsCountryCode = {
  baseURL: 'https://ipapi.co/json/',
  headers: {
    'Content-Type': 'application/json',
  },
}

const authSignUp = {
  baseURL: 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=AIzaSyCGq4LY1ElS1nzwq_cDAAe3ubpoKwYFaPs',
  headers: {
    'Content-Type': 'application/json',
  },
}

const authSignIn = {
  baseURL: 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyCGq4LY1ElS1nzwq_cDAAe3ubpoKwYFaPs',
  headers: {
    'Content-Type': 'application/json',
  },
}

export const instanceSignUp = axios.create(authSignUp)
export const instanceSignIn = axios.create(authSignIn)

export const instance = axios.create(defaultOptions)
export const instanceData = axios.create(defaultOptions)
export const instanceCountryCode = axios.create(defaultOptionsCountryCode)

instanceData.interceptors.request.use(function (config) {
    const user = sessionStorage.getItem('cadiumUser')
    const token = JSON.parse(user)
    config.headers.Authorization =  token.token ? `Bearer ${token.token}` : '';
    config.headers.post['Access-Control-Allow-Origin'] = '*';
    return config
})

