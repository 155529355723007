import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import arrowIcon from  '../../../assets/img/builder/datepicker.svg'

const Terms = ({dataInput, setDataInput}) => {
  return (
    <>
    <div className="block-two-row mobile-select">
      <div>
          <label
            className="label"
            htmlFor="startDate"
          >
            Start date
          </label>
        <div className="date-picker">
          <img src={arrowIcon} alt="arrow"/>
            <DatePicker dateFormat="dd/MM/yyyy"
              selected={Date.parse(dataInput?.startYear) || ""}
              placeholderText="dd/mm/yyyy"
              className="form-input"
              onChange={(date) =>  {
                setDataInput({...dataInput, startYear: date})
              }}
            />
        </div>
      </div>
      <div>
        <label
          className="label"
          htmlFor="endYear"
        >
          End date
        </label>
        <div className="date-picker">
          <img src={arrowIcon} alt="arrow"/>
          <DatePicker dateFormat="dd/MM/yyyy"
            selected={Date.parse(dataInput?.finishYear) || ''}
            placeholderText="dd/mm/yyyy"
            className="form-input"
            onChange={(date) =>  {
              setDataInput({...dataInput, finishYear: date})}}
          />
        </div>
      </div>
    </div>
      {Date.parse(dataInput?.startYear) > Date.parse(dataInput?.finishYear) &&
        <div className="characters-left">
          <p className="error-message error">Entered dates are not correct</p>}
        </div>
      }
    </>
  )
}

export default Terms