import {useState} from 'react'
import './Auth.sass'
import { Outlet } from 'react-router-dom'
import logo from "../../assets/img/auth/logo.svg"
import {emailValidation} from "../../consts/utils";

export const AuthBtn = (props) => {
  return (
    <button className="btn btn-auth" type="submit" disabled={props.isDisabled} onClick={props.onClick}>
      <img src={props.img} alt={props.title}/>{props.title}
    </button>
  )
}

const Auth = () => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    resetEmail: ''
  })

  const [errors, setErrors] = useState({})

  const validate = (rules, field, val) => {
    if (!rules) return true
    let isValid = true
    const errorsObj = field ? {...errors} : {}
    const handleError = (message, fieldName) => {
      isValid = false
      errorsObj[fieldName] = message
    }
    const checkField = (rule, field, val) => {
      if ((rule[field].required || rule[field].email) && !val) handleError('The field is required', field)
      if (rule[field].email && val && emailValidation(val)) {handleError('Email is invalid', field)}
      if ((rule[field].password && val?.length < 6)) handleError('Must be at least 6 characters', field)
      if (rule[field].confirmPassword && val !== values.password) handleError('Both passwords must match', field)
    }
    if (field) {
      errorsObj[field] = null
      checkField(rules.find(rule => rule[field]), field, val)
    } else {
      rules.forEach(rule => {
        const fieldName = Object.keys(rule)[0]
        checkField(rule, fieldName, val)
      })
    }
    setErrors(errorsObj)
    return isValid
  }

  return (
    <section className="auth dashboard-layout">
      <div className="auth-container container">
        <div className="auth-image">
          <img src={logo} alt="cadium.io" width="124" height="24"/>
        </div>
          <Outlet context={[values, setValues, errors, validate, setErrors]}/>
      </div>
    </section>
  );
};

export default Auth