const websiteTypes = {
   SET_WEBSITE_START: 'SET_WEBSITE_START',
   SET_WEBSITE_SUCCESS: 'SET_WEBSITE_SUCCESS',
   SET_WEBSITE_FAIL: 'SET_WEBSITE_FAIL',

   ADD_WEBSITE_START: 'ADD_WEBSITE_START',
   ADD_WEBSITE_FAIL: 'ADD_WEBSITE_FAIL',
   ADD_WEBSITE_SUCCESS: 'ADD_WEBSITE_SUCCESS',

   DELETE_WEBSITE_START: 'DELETE_WEBSITE_START',
   DELETE_WEBSITE_SUCCESS: 'DELETE_WEBSITE_SUCCESS',
   DELETE_WEBSITE_FAIL: 'DELETE_WEBSITE_FAIL',

   GET_WEBSITE_EXIST: 'GET_WEBSITE_EXIST',
   CLEAR_WEBSITE_DATA: 'CLEAR_WEBSITE_DATA',

   NOT_FOUNT_PAGE: 'NOT_FOUNT_PAGE'
};

export default websiteTypes;