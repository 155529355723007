import '../Auth.sass'
import Builder from "./Builder"
import PersonalData from "./steps/PersonalData"
import { useOutletContext } from 'react-router-dom'
import CreatePassword from "./steps/CreatePassword"
import {signUpWithPassword} from "../../../store/User/user.actions"
import {useDispatch} from "react-redux"

const SignUp = () => {
  const dispatch = useDispatch()
  const [values, setValues, errors, validate, setErrors] = useOutletContext()

  const steps = [
    {
      content: <PersonalData
        values={values}
        errors={errors}
        setValues={setValues}
        validate={validate}
      />
    },
    {
      content: <CreatePassword
        values={values}
        errors={errors}
        setValues={setValues}
        validate={validate}
      />
    }
  ]

  const submit = () => dispatch(signUpWithPassword(values.name, values.email, values.password))

  return (
    <>
      <Builder
        values={values}
        content={steps}
        submit={() => submit()}
        errors={errors}
        setErrors={setErrors}
      />
    </>
  )
}

export default SignUp