import './Footer.sass'
import PrivacyPolicy from '../../../assets/documents/privacy-policy.pdf'
import TermsAndConditions from '../../../assets/documents/terms-and-conditions.pdf'
import CookiePolicy from '../../../assets/documents/cookie-policy.pdf'
import logoWhite from '../../../assets/img/auth/logo-white.svg'
import facebook from '../../../assets/img/auth/social-networks/facebook.svg'
import twitter from '../../../assets/img/auth/social-networks/twitter.svg'
import instagram from '../../../assets/img/auth/social-networks/instagram.svg'

const Footer = () => {
  return(
    <footer>
      <div className="container">
        <div className="main">
          <div><img src={logoWhite} alt="cadium.io" width="124" height="24"/></div>
          <div className="links-menu">
            <ul>
              <li><a href="mailto:hello@cadium.io">Support</a></li>
              <li><a href={PrivacyPolicy} target="_blank" rel="noopener noreferrer">Privacy policy</a></li>
              <li><a href={TermsAndConditions} target="_blank" rel="noopener noreferrer">Terms and Conditions</a></li>
              <li><a href={CookiePolicy} target="_blank" rel="noopener noreferrer">Cookie policy</a></li>
            </ul>
          </div>
        </div>
        <div className="social-networks">
          <ul>
            <li><a href="https://www.facebook.com/cadium.io/" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="cadium.io facebook" width="25" height="25"/></a>
            </li>
            <li><a href="https://twitter.com/cadium_io" target="_blank" rel="noopener noreferrer">
              <img src={twitter} alt="cadium.io twitter" width="25" height="25"/></a>
            </li>
            <li><a href="https://www.instagram.com/cadium.io/" target="_blank" rel="noopener noreferrer">
              <img src={instagram} alt="cadium.io instagram" width="25" height="25"/></a>
            </li>
          </ul>
          <p>&copy; {new Date().getFullYear()} cadium.io</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer