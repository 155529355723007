import 'firebase/compat/auth'
import userTypes from './user.types'
import {instance, instanceSignUp, instanceSignIn} from "../../api/api_instance"
import {auth, GoogleProvider} from "../../firebase/utils"

export const authStart = () => {
  return {type: userTypes.AUTH_START}
}

export const authFail = (error) => {
  return {
    type: userTypes.AUTH_FAIL,
    error: error
  }
}

export const clearAuthFail = () => {
  return {
    type: userTypes.CLEAR_AUTH_FAIL,
  }
}

export const setCurrentUser = user => ({
  type: userTypes.SET_CURRENT_USER,
  payload: user
})

export const resetPasswordMessage = () => ({
  type: userTypes.RESET_PASSWORD_MESSAGE,
})

export const errorResetPasswordMessage = () => ({
  type: userTypes.ERROR_RESET_PASSWORD_MESSAGE,
})

export const sendMessageResetPassword = () => ({
  type: userTypes.SEND_MESSAGE_RESET_PASSWORD,
})

export const responseGoogleSuccess = (token) => {
  return (dispatch) => {
    dispatch(authStart())
    return instance({url: "auth/google", method: "POST", data: {idToken: token}})
      .then(response => {
        dispatch(setCurrentUser(response.data.user))
        sessionStorage.setItem('cadiumUser', JSON.stringify(response.data))
      }).catch(error => {
        dispatch(authFail(error))
      })
    }
}

export const responseClassicSignUpSuccess = (token, name) => {
  return (dispatch) => {
    dispatch(authStart())
    return instance({url: "auth/classic", method: "POST", data: {idToken: token, name: name}})
      .then(response => {
        dispatch(setCurrentUser(response.data))
        sessionStorage.setItem('cadiumUser', JSON.stringify(response.data))
      }).catch(error => {
        dispatch(authFail(error))
      })
  }
}

export const responseClassicSignInSuccess = (token, checked) => {
  return (dispatch) => {
    dispatch(authStart())
    return instance({url: "auth/classic", method: "POST", data: {idToken: token}})
      .then(response => {
        dispatch(setCurrentUser(response.data))
        checked ?
          localStorage.setItem('cadiumUser', JSON.stringify(response.data))
          : sessionStorage.setItem('cadiumUser', JSON.stringify(response.data))
      }).catch(error => {
        dispatch(authFail(error))
      })
  }
}

export const signInWithGoogle = () => async dispatch => {
    await auth.signInWithPopup(GoogleProvider)
      .then((response) => {
        dispatch(responseGoogleSuccess(response.credential.idToken))
      }).catch(error => dispatch(authFail(error)))
}

export const signUpWithPassword = (name, email, password) => async dispatch => {
  const authData = {
    email: email,
    password: password,
    returnSecureToken: true
  };
  instanceSignUp({method: "POST", data: authData})
    .then((response) => {
      dispatch(responseClassicSignUpSuccess(response.data.idToken, name))
    }).catch((error) => {
      dispatch(authFail(error.response.data.error))
  });
}

export const signInWithPassword = (email, password, checked) => async dispatch => {
      const authData = {
        email: email,
        password: password,
        returnSecureToken: true
      }

   instanceSignIn({method: "POST", data:authData})
     .then((response) => {
          dispatch(responseClassicSignInSuccess(response.data.idToken, checked))
        }).catch((error) => {
        dispatch(authFail(error.response.data.error))
   });
}

export const resetPassword = (currentEmail) => async dispatch => {
  dispatch(sendMessageResetPassword())
  await auth.sendPasswordResetEmail(currentEmail)
    .then(() => {
      dispatch(resetPasswordMessage())
      dispatch(clearAuthFail(null))
    })
    .catch((error) => {
      dispatch(authFail(error))
      dispatch(errorResetPasswordMessage())
    })
}


