import {useState, useEffect} from "react"
import Card from "../../../card/Card"
import Input from "../../../inputs/Input"
import TextArea from "../../../inputs/TextArea"
import Terms from "../../../terms/Terms"
import FormBtns from "../../../formBtns/FormBtns"
import Modal from "../../../../Modal/Modal"
import {overflowHidden} from "../../../../../consts/utils"

const WorkExperience = (
  {workExperience, addWorkExperienceItem, removeWorkExperienceItem, updateWorkExperienceItem,
    errors, setErrors, validate, validateSubmit, limitWorkExperience, limitInputsWorkExperience}) => {

  const initialProject = {
    id: new Date().getUTCMilliseconds(),
    position: '',
    company: '',
    city: '',
    country: '',
    startMonth: '',
    startYear: '',
    finishMonth: '',
    finishYear: '',
  }

  const [dataInput, setDataInput] = useState(initialProject)
  const [openModal, setOpenModal] = useState(false)

  const handleChange = (fieldName, value) => {
    errors[fieldName] && validate(fieldName, dataInput)
    const updatedValues = {...dataInput, [fieldName]: value}
    setDataInput(updatedValues)
  }

  useEffect(() => {overflowHidden(openModal)}, [openModal])

  return (
    <div>
      <div>
        <div className="d-flex align-baseline justify-between">
          <h4 className="form-section-title">Work Experience (max. {limitWorkExperience})</h4>
          <button
            className="add-btn"
            type="button"
            disabled={workExperience?.length === limitWorkExperience}
            onClick={() => {setDataInput(initialProject); setOpenModal(!openModal)}}
          >
            <span>+</span> Add experience
          </button>
        </div>
      </div>
      {workExperience?.length > 0 &&
        workExperience?.map((project, index) =>
          <Card
            key={index}
            hiddenElements={true}
            project={project}
            deleteProject={() => removeWorkExperienceItem(index)}
            editProject={() => {
              setOpenModal(!openModal)
              setDataInput({...project, editing: true})
            }
          }
          />
        )
      }
      {!workExperience?.length ? <div className="border-line-bottom"/> : null}
      {openModal && <Modal title="Add the working experience" setOpenModal={setOpenModal}
                           setErrors={setErrors}>
          <form onSubmit={(event) => {
            event.preventDefault()
            if (validateSubmit(dataInput)) {
              dataInput.editing ?
                updateWorkExperienceItem({...dataInput, editing: false}) :
                addWorkExperienceItem(dataInput)
              setDataInput(null)
              setOpenModal(!openModal)
            }
          }}>
            <div className="block-two-row mobile">
              <div>
                <Input
                  placeholder="Enter your position"
                  label="Position"
                  name="title"
                  type="text"
                  value={dataInput.position || ""}
                  error={errors?.position}
                  handleChange={(event) => handleChange('position', event.target.value)}
                  handleBlur={() => validate('position', dataInput)}
                  inputLimit={limitInputsWorkExperience.position}
                  count="true"
                  require="true"
                />
              </div>
              <div>
                <Input
                  placeholder="Enter your company"
                  label="Company"
                  name="title"
                  type="text"
                  value={dataInput.company || ""}
                  error={errors.company}
                  handleChange={(event) => handleChange('company', event.target.value)}
                  handleBlur={() => validate('company', dataInput)}
                  inputLimit={limitInputsWorkExperience.company}
                  count="true"
                  require="true"
                />
              </div>
            </div>
            <div className="border-line-bottom"/>
            <div className="block-two-row mobile">
              <div>
                <Input
                  placeholder="Enter your country"
                  label="Country"
                  name="title"
                  type="text"
                  value={dataInput.country || ""}
                  error={errors?.country}
                  handleChange={(event) => setDataInput({...dataInput, country: event.target.value})}
                  inputLimit={limitInputsWorkExperience.country}
                  count="true"
                />
              </div>
              <div>
                <Input
                  placeholder="Enter your city"
                  label="City"
                  name="title"
                  type="text"
                  value={dataInput.city || ""}
                  handleChange={(event) => setDataInput({...dataInput, city: event.target.value})}
                  inputLimit={limitInputsWorkExperience.city}
                  count="true"
                />
              </div>
            </div>
            <div className="border-line-bottom"/>
            <Terms setDataInput={setDataInput} dataInput={dataInput}/>
            <div className="border-line-bottom"/>
            <div className="form-row">
              <TextArea
                labelClassName="form-label form-label"
                id="Description"
                className="form-textarea"
                placeholder="Enter your description"
                value={dataInput.description || ""}
                label="About the experience"
                name="description"
                error={errors.description}
                handleChange={(event) =>
                  setDataInput({...dataInput, description: event.target.value})}
                inputLimit={limitInputsWorkExperience.description}
              />
            </div>
            <FormBtns
              title="Add experience"
              dataInput={dataInput}
              setDataInput={setDataInput}
              setOpenModal={setOpenModal}
              isDisabled={openModal && (
                (!dataInput.position || !dataInput.company) ||
                (dataInput.position.trim() === "" || dataInput.company.trim() === "") ||
                (dataInput?.startYear > dataInput?.finishYear))}
            />
          </form>
      </Modal>}
    </div>
  )
}

export default WorkExperience