import {useEffect} from "react"
import WorkExperience from "./WorkExperience/WorkExperience"
import Education from "./Education/Education"
import {workExperienceRules, educationRules} from "../../../../consts/validationRules"
import {firebaseAnalytics} from "../../../../firebase/utils";

const WorkAndEducation = props => {
  useEffect(() => {firebaseAnalytics.logEvent('WorkAndEducation_visited')}, [])
  return(
    <div>
      <WorkExperience
        workExperience={props.getField('workExperience')}
        addWorkExperienceItem={(item) => props.addFieldItem(item, 'workExperience')}
        updateWorkExperienceItem={(item) => props.updateFieldItem(item, 'workExperience')}
        removeWorkExperienceItem={(index) => props.removeFieldItem(index, 'workExperience')}
        errors={props.errors}
        validate={(field, dataInput) => props.validate(workExperienceRules, field, dataInput)}
        validateSubmit={(dataInput) => props.validate(workExperienceRules, null, dataInput)}
        limitWorkExperience={props.limitWorkExperience}
        limitInputsWorkExperience={props.limitInputsWorkExperience}
        setErrors={props.setErrors}
      />
      <Education
        education={props.getField('education')}
        addEducationItem={(item) => props.addFieldItem(item, 'education')}
        updateEducationItem={(item) => props.updateFieldItem(item, 'education')}
        removeEducationItem={(index) => props.removeFieldItem(index, 'education')}
        errors={props.errors}
        validate={(field, dataInput) => props.validate(educationRules, field, dataInput)}
        validateSubmit={(dataInput) => props.validate(educationRules, null, dataInput)}
        limitEducation={props.limitEducation}
        limitInputsEducation={props.limitInputsEducation}
        setErrors={props.setErrors}
      />
    </div>
  )
}

export default WorkAndEducation
