export const personalInfoRules = [
  {fullName: {required: true}},
  {jobTitle: {required: true}},
  {aboutMe: {str: true}},
  {location: {str: true}},
  {email: {email: true}}
]

export const portfolioRules = [
  {title: {required: true}},
  {projectLink: {str: true}},
  {startYear: {startYear: true}},
  {finishYear: {finishYear: true}}
]

export const workExperienceRules = [
  {position: {required: true}},
  {company: {required: true}},
  {city: {str: true}},
  {country: {str: true}},
]

export const educationRules = [
  {studies: {required: true}},
  {institution: {required: true}},
  {city: {str: true}},
  {country: {str: true}},
]

export const certificationRules = [
  {title: {required: true}},
  {description: {str: true}},
]

export const signInRules = [
  {email: {email: true}},
  {password: {required: true}},
]

export const signupInfoRules = [
  {name: {required: true}},
  {email: {email: true}},
]

export const signupPasswordRules = [
  {password: {password: true}},
  {confirmPassword: {confirmPassword: true}}
]

export const passwordResetRules = [
  {resetEmail: {email: true}},
]

