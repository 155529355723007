import './Loader.sass'

const Loader = ({login}) => (
  <div className="loader">
      <svg className="loading-spinner" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle className="loading-circle-large" cx="40" cy="40" r="36" stroke="#006D77FF" strokeWidth="8" />
      </svg>
    {login && <h1>Please, wait a little</h1>}
  </div>
)

export default Loader