import { useState, useEffect } from "react"
import Input from "../../inputs/Input"
import TextArea from "../../inputs/TextArea"
import Card from "../../card/Card"
import FormBtns from "../../formBtns/FormBtns"
import Modal from "../../../Modal/Modal"
import {certificationRules} from "../../../../consts/validationRules"
import {overflowHidden} from "../../../../consts/utils"
import PortfolioTagsInput from "../Portfolio/Tag/PortfolioTagsInput";
import {firebaseAnalytics} from "../../../../firebase/utils";

const Certifications = ({certifications, skills, addFieldItem, updateFieldItem, removeFieldItem,
errors, validate, setErrors, validateSubmit, limitCertifications, limitInputs}) => {

  const initialProject = {
    id: new Date().getUTCMilliseconds(),
    title: "",
    description: "",
    relatedSkills: [],
    relatedLanguages: [],
  }

  const [dataInput, setDataInput] = useState(initialProject)
  const [openModal, setOpenModal] = useState(false)

  const handleChange = (fieldName, value) => {
    errors[fieldName] && validate(fieldName, dataInput)
    const updatedValues = {...dataInput, [fieldName]: value}
    setDataInput(updatedValues)
  }

  useEffect(() => {overflowHidden(openModal)}, [openModal])
  useEffect(() => {firebaseAnalytics.logEvent('certifications_visited')}, [])
  return (
    <>
      <div className="d-flex align-baseline justify-between">
        <h4 className="form-section-title">Certifications (max. {limitCertifications})</h4>
        <button className="add-btn"
          type="button"
          disabled={certifications?.length === limitCertifications}
          onClick={() => {setDataInput(initialProject); setOpenModal(!openModal)}}
        >
          <span>+</span> Add certificate
        </button>
      </div>
      <div className="certifications">
        {certifications?.length > 0 &&
          certifications?.map((project, index) =>
              <Card
                key={index}
                project={project}
                hiddenElements={true}
                deleteProject={() => removeFieldItem(index)}
                editProject={() => {
                  setOpenModal(!openModal)
                  setDataInput({...project, editing: true})
                  window.scrollTo({top: 150, left: 0, behavior: "smooth"})
                }}
              />
          )
        }
        {!certifications?.length ? <div className="border-line-bottom"/> : null}
      </div>
      {openModal && <Modal title="Add the certificate" setOpenModal={setOpenModal} setErrors={setErrors}>
        {dataInput && (
          <form onSubmit={(event) => {
            event.preventDefault()
            if (validateSubmit(certificationRules, dataInput)) {
              dataInput.editing ?
                updateFieldItem({...dataInput, editing: false}) :
                addFieldItem(dataInput)
              setDataInput(null)
              setOpenModal(!openModal)
            }
          }}>
            <div>
              <Input
                labelClassName="form-label form-label"
                placeholder="Enter Certificate Name"
                name="certificateName"
                label="Certificate Name"
                type="text"
                value={dataInput.title || ""}
                error={errors.title}
                handleChange={(event) => handleChange('title', event.target.value)}
                handleBlur={() => validate('title', dataInput)}
                inputLimit={limitInputs.title}
                require="true"
              />
            </div>
            <PortfolioTagsInput
              setDataInput={(value) => setDataInput({ ...dataInput, relatedSkills: value})}
              tags={dataInput.relatedSkills}
              label="Skills"
              placeholder="Enter the tools"
              limitInput={limitInputs.skills}
              suggestions={skills}
            />
            <div className="border-line-bottom"/>
            <div>
              <TextArea
                labelClassName="form-label form-label"
                placeholder="Write about your work"
                name="description"
                label="Description"
                type="text"
                value={dataInput.description || ""}
                handleChange={(event) => handleChange('description', event.target.value)}
                inputLimit={limitInputs.description}
              />
              <div className="border-line-bottom"/>
            </div>
            <FormBtns
              title="Add certificate"
              dataInput={dataInput}
              setDataInput={setDataInput}
              setOpenModal={setOpenModal}
              isDisabled={openModal &&
                (!dataInput.title || dataInput.title.trim() === "")}
              setErrors={setErrors}
            />
          </form>
        )}
      </Modal>}
    </>
  );
};

export default Certifications
