import {useState, Fragment, useEffect} from "react"
import {Link} from "react-router-dom"
import {AuthBtn} from "../Auth"
import GetStartedButton from "../components/GetStartedButton/GetStartedButton"
import btnIcon from "../../../assets/img/auth/btn-icon.svg"
import btnContinueIcon from '../../../assets/img/auth/btn-icon-continue.svg'
import {clearAuthFail} from "../../../store/User/user.actions"
import {useDispatch, useSelector} from "react-redux"
import {emailValidation, getErrorTypeMessage} from "../../../consts/utils"
import {firebaseAnalytics} from "../../../firebase/utils";

const Navigation = props => {
  const dispatch = useDispatch()
  const [loadingType, setLadingType] = useState(false)

  return (
    <>
      <AuthBtn title={props.loading && loadingType ? 'Loading...' : props.isLastStep ? 'Sign up' : 'Continue'}
        img={props.isLastStep ? btnIcon : btnContinueIcon}
        onClick={(e) => {
          setLadingType(true)
          props.isLastStep ? props.submitHandler(e) : props.nextStepHandler(e)
          firebaseAnalytics.logEvent("click_sign_up_password")
        }}
        isDisabled={
        props.currentTabIndex === 0 ? emailValidation(props.values.email) || !props.values.name?.length > 0
          : props.values.password !== props.values.confirmPassword
          || !props.values.password?.length > 0 || !props.values.confirmPassword?.length > 0
          || props.errors?.password?.length > 0 || props.errors?.confirmPassword?.length > 0
        }
      />
      {props.currentTabIndex === 0  &&
        props.globalError && <p className="error global-error">{getErrorTypeMessage(props.globalError)}</p>}
      {props.currentTabIndex === 0  &&
        <>
          <p className="auth-line">or</p>
          <GetStartedButton primary title={props.loading && !loadingType ? 'Loading...' : 'Sign up with Google'} icon={'google'}/>
        </>
      }
      <p className="description">Already have an account?
        <Link to='/' onClick={() => {
          dispatch(clearAuthFail(null)); props.setErrors({})
        }}>Back to Sign in</Link></p>
    </>
  )
}

const Builder = ({values, content, submit, errors, setErrors}) => {
  const mapState = ({user}) => ({globalError: user.error, loading: user.loading})
  const {globalError, loading} = useSelector(mapState)
  const dispatch = useDispatch()

  const [currentTabIndex, setCurrentTabIndex] = useState(0),
    isLastStep = currentTabIndex === content.length - 1

  const nextStepHandler = e => {
    e.preventDefault()
    setCurrentTabIndex((prev) => {
      if (prev !== content.length - 1) return prev + 1
    })
  }

  useEffect(() => setCurrentTabIndex(0), [globalError, setCurrentTabIndex])

  const submitHandler = (e) => {
    e.preventDefault()
    submit()
    dispatch(clearAuthFail(null))
  }

  return (
    <div className="auth-form">
      {content.map((el, i) => <Fragment key={i}>{i === currentTabIndex && el.content}</Fragment>)}
      <Navigation
          values={values}
          isLastStep={isLastStep}
          nextStepHandler={nextStepHandler}
          submitHandler={submitHandler}
          content={content}
          currentTabIndex={currentTabIndex}
          errors={errors}
          setErrors={setErrors}
          globalError={globalError}
          loading={loading}
        />
    </div>
  )
}

export default Builder
