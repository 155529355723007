import {useState, useEffect, useCallback, useRef} from "react"
import ReactTags from "react-tag-autocomplete"
import Select from "react-select"
import Modal from "../../../Modal/Modal"
import {overflowHidden} from "../../../../consts/utils"
import {languageLevels, languages} from "../../../../consts/languages"
import trashIcon from "../../../../assets/img/builder/trash-icon.svg"
import editBtn from "../../../../assets/img/builder/edit-icon.svg"
import addIcon from "../../../../assets/img/builder/add-tag-btn.svg"
import {firebaseAnalytics} from "../../../../firebase/utils";

const SkillsAndLanguages = ({values, setValues, limitLanguages, limitSkills, limitInput, updateLangItem, addLangItem,
   setErrors}) => {
  useEffect(() => {firebaseAnalytics.logEvent('skillsAndLang_visited')}, [])

  const initialLanguage = {
    id: new Date().getUTCMilliseconds(),
    language: '',
    level: '',
  }

  const skillsTags = useRef()
  const [query, setQuery] = useState(null)
  const [charLeft, setCharLeft] = useState(limitInput)

  const [openModal, setOpenModal] = useState(false)
  const [dataLanguage, setDataLanguage] = useState(initialLanguage)

  const updateValue = (fieldName, value) => {
    const updatedValues = {...dataLanguage, [fieldName]: value}
    setDataLanguage(updatedValues)
  }

  useEffect(() => {overflowHidden(openModal)}, [openModal])

  const deleteSkill = useCallback((tagIndex) =>
    setValues('skills', values.skills.filter((_, i) => i !== tagIndex)), [setValues, values.skills])

  const addSkill = useCallback((newTag) => {
    if (query?.trim() === "") return
    if (!newTag.name && query?.length !== 0) {
      setValues('skills', [...values.skills, {name: query}])
      skillsTags.current?.clearInput()
    } else {
      setValues('skills', [...values.skills, {name: newTag.name, id: new Date().getMilliseconds()}])
    }
    setQuery(null)
  }, [setValues, values.skills, query])

  const AddLanguage = ({dataLanguage, updateValue}) => {

    return (
      <>
        <>
          <div>
            <Select
              placeholder={'Select language'}
              openMenuOnFocus={true}
              options={languages}
              value={languages.find(o => o.value === dataLanguage.language)}
              onChange={(language) => updateValue('language', language.value)}/>
          </div>
          {dataLanguage.language &&
            languageLevels.map((item, index) => (
              <div key={index} className="form-radio">
                <input type="radio" id={item.value}
                   value={dataLanguage.level}
                   name={item.value}
                   checked={dataLanguage.level === item.value}
                   onChange={() => updateValue('level', item.value)}/>
                <label htmlFor={item.value}>
                  <span>{item.label}</span>
                  <p>{item.description}</p>
                </label>
              </div>
            ))
          }
        </>
        <div className="stepper-footer form-btn-block">
          <button className="btn-transparent" type="button"
            onClick={() => {
              setOpenModal(!openModal)
              setDataLanguage({...dataLanguage, editing: false})}}>Cancel</button>
          <button
            className="btn btn-continue" type="button"
            disabled={dataLanguage?.level.length <= 0}
            onClick={() => {
              dataLanguage.editing ?
                updateLangItem({...dataLanguage, editing: false}):
                addLangItem(dataLanguage)
              setDataLanguage(initialLanguage)
              setOpenModal(!openModal)
            }}> {dataLanguage?.editing ? 'Edit' : 'Add language'}</button>
        </div>
      </>
    )
  }

  return (
      <form onSubmit={e => {e.preventDefault()}}>
        <>
          <h4 className="form-section-title">Skills (max. {limitSkills})</h4>
          <>
            <div className="react-tags-block d-flex mb-2">
              <ReactTags
                allowNew
                ref={skillsTags}
                onDelete={deleteSkill}
                onAddition={addSkill}
                minQueryLength={1}
                onInput={(query) => {
                  const charCount = query.length
                  const char = limitInput - charCount
                  if (char <= -1) return false
                  setQuery(query)
                  setCharLeft(char)
                }}
                placeholderText={values?.skills?.length === limitSkills ? "Max. limit Skills" : "Enter Skill"}
                inputAttributes={values?.skills?.length === limitSkills ? {disabled: true, maxLength: 70} :
                  {disabled: false, maxLength: 70}}
              />
              {query && <button type="button" disabled={query.length === limitInput || query.trim() === ""}
                                className="btn btn-click" onClick={addSkill}><img src={addIcon} alt="add"/></button>}
              {query && <div className="characters-left">
                <span>{charLeft}/{limitInput}</span>
              </div>
              }
            </div>
            <div>
              {values.skills?.map((tag, index) => (
                <div
                  onClick={() => deleteSkill(index)}
                  className={`primary-tag accent-color-tag ` + (tag?.name.length > 40 ? "long": '')}
                  key={index}
                >
                 <span className={tag?.name.length > 40 ? "primary-tag-long": ''}>{tag.name}</span>
                </div>
              ))}
            </div>
          </>
        </>
        <hr className="border-line-bottom"/>
        <>
          <div className="d-flex align-baseline justify-between">
            <h4 className="form-section-title">Languages (max. {limitLanguages})</h4>
            <button
              className="add-btn"
              type="button"
              disabled={values?.languages?.length === limitLanguages}
              onClick={() => {setOpenModal(!openModal); setDataLanguage(initialLanguage)}}
            >
              <span>+</span> Add language
            </button>
          </div>
          <>
            {values.languages?.map((item, index) => {
              return (
                <div className="lang-block" key={index}>
                  <div className="lang-data">
                    <p>{item.language}:</p>
                    <p className="lang-level">{item.level}</p>
                  </div>
                  <div className="lang-btn-block">
                    <button type="button" className="btn-icon edit"
                      onClick={() => {
                        setDataLanguage({...item, editing: true})
                        setOpenModal(true)
                      }}>
                      <img src={editBtn} alt="edit"/>
                    </button>
                      <button type="button" className="btn-icon"
                        onClick={() => {
                          const languages = [...values.languages]
                          languages.splice(index, 1)
                          setValues('languages', languages)
                      }}>
                        <img src={trashIcon} alt="delete"/>
                      </button>
                  </div>
               </div>
              )
            })}
          </>
        </>
        <hr className="border-line-bottom"/>
        {openModal && <Modal setOpenModal={setOpenModal} title="Add language" setDataLanguage={setDataLanguage}
                             setErrors={setErrors} initialLanguage={initialLanguage}
                             classType={`modal-small language ${dataLanguage.language && openModal}`}>
          <AddLanguage dataLanguage={dataLanguage} openModal={openModal} updateValue={updateValue}/>
        </Modal>}
      </form>
  )
}

export default SkillsAndLanguages
