import {useState, useRef, useCallback} from "react"
import ReactTags from "react-tag-autocomplete"
import addIcon from "../../../../../assets/img/builder/add-tag-btn.svg"

const PortfolioTagsInput = ({tags, setDataInput, limitInput, ...props}) => {
  const tagRef = useRef()
  const [query, setQuery] = useState(null)
  const [charLeft, setCharLeft] = useState(limitInput)

  const addTag = useCallback((newTag) => {
    if (!newTag.name && query?.length !== 0) {
      setDataInput([...tags, query])
      tagRef.current?.clearInput()
    } else {
      setDataInput([...tags, newTag.name])
    }
    setQuery(null)
  }, [setDataInput, tags, query])

  const deleteTag = (tagIndex) => setDataInput(tags.filter((_, i) => i !== tagIndex))

  return (
    <div className="form-row">
      <div className="mb-15">
        <label className="label">{props.label}</label>
        <div className="react-tags-block d-flex">
          <ReactTags
            allowNew
            placeholderText={props.placeholder}
            ref={tagRef}
            onDelete={deleteTag}
            onAddition={addTag}
            minQueryLength={1}
            onInput={query => {
              const charCount = query.length
              const char = limitInput - charCount
              if (char <= -1) return false
              setQuery(query)
              setCharLeft(char)
            }}
            suggestions={props.suggestions ? props.suggestions : undefined}
            inputAttributes={{maxLength: 70}}
          />
          {query && <button type="button" className="btn" onClick={addTag}><img src={addIcon} alt="add"/></button>}
          {query && <div className="characters-left">
            <span>{charLeft}/{limitInput}</span>
          </div>
          }
        </div>
      </div>
      {tags?.map((tag, index) => (
        <div
          className={`primary-tag accent-color-tag ` + (tag?.length > 40 ? "long": '')}
          key={index}
          onClick={() => deleteTag(index)}
        >
          <span className={tag.length > 40 ? "primary-tag-long": ''}>{tag}</span>
        </div>
      ))}
    </div>
  )
}

export default PortfolioTagsInput