import firebase from "firebase/compat/app"
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import {firebaseConfig} from "./config"

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()

export const GoogleProvider = new firebase.auth.GoogleAuthProvider()
GoogleProvider.setCustomParameters({ prompt: 'select_account' })

export const firebaseAnalytics = firebase.analytics()