import {useEffect, useState, useCallback} from "react"

const TextArea = ({handleChange, handleBlur, labelClassName, label, inputLimit, error, ...otherProps}) => {
  const charLength = inputLimit || 100

  useEffect(() => setCharLeft(otherProps?.value?.length), [otherProps?.value?.length])

  const [charLeft, setCharLeft] = useState(
    otherProps?.value?.length ? otherProps?.value?.length : charLength)

  const handleKeyPressTextarea = useCallback((e) => {
    const charCount = e.target.value.length
    if (charCount <= -1) return false
    setCharLeft(charCount)
  }, [])

  return (
    <div className="form-row">
      {label && (
        <label className="label" htmlFor={label}>
          {label}
        </label>
      )}
      <textarea
        id={label}
        className="form-textarea"
        onChange={(e) => {
          handleKeyPressTextarea(e);
          handleChange(e);
        }}
        maxLength={inputLimit}
        onBlur={handleBlur}
        {...otherProps}
      />
       <div className="characters-left">
         {error && <p className={`error-message ` + (error && `error`)}>{error}</p>}
         {!otherProps.count && <span>{charLeft}/{charLength}</span>}
      </div>

    </div>
  );
}

export default TextArea