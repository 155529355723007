import Compressor from "compressorjs"

const ImageUploader = ({addImage, setErrorImg, errorImg}) => {
  const handleImageChange = (e) => {
    const acceptedFiles = e.target.files
    const imageFile = acceptedFiles[0]

    new Compressor(imageFile, {
      convertTypes: ['image/png', 'image/webp', 'image/heic', 'image/heif', 'image/bmp', 'image/svg', 'image/tif', 'image/tiff', 'image/gif', 'image/raw'],
      convertSize: 0,
      maxWidth: 1000,
      maxHeight: 1000,
      success: (results) => {
        const newImages = Array.from(acceptedFiles)?.map((file) => {
          return {file: results, imagesPreviewUrls: URL.createObjectURL(file)}
        })
        addImage(newImages)
        setErrorImg()
      },
      error(err) {
        setErrorImg('The first argument \n must be an image File')
      }
    })
  }

  return (
    <div className="form-row">
      <input
        id="filesImg"
        className="upload-project-img"
        type="file"
        accept="image/*, .heic, .heif"
        onChange={(e) => handleImageChange(e)}
      />
      <div className="project-img-block">
        <label htmlFor="filesImg" className="upload-file">
          <div>Attach Photo</div>
          {errorImg && <p className={`error-message ` + (errorImg && `error`)}>{errorImg}</p>}
        </label>
      </div>
    </div>
  )
}

export default ImageUploader