export const INITIAL_STATE = {
  avatar: {},
  fullName: '',
  jobTitle: '',
  preferredUrl: '',
  aboutMe: '',
  email: '',
  phoneNumber: '',
  location: '',
  socialNetworks: [],
  skills: [],
  languages: [],
  portfolio: [],
  workExperience: [],
  education: [],
  certifications: [],
  ready: false
}

export const LIMIT_STATE = {
  socialNetworks: 5,
  skills: 6,
  languages: 3,
  portfolio: 4,
  portfolioImages: 3,
  workExperience: 3,
  education: 3,
  certifications: 3,
}

export const INPUTS_LIMIT_STATE = {
  personalInformation: {
    fullName: 70,
    jobTitle: 70,
    preferredUrl: 70,
    aboutMe: 500,
    email: 320,
    location: 255,
    socialNetworksId: 255
  },
  skillLen: 70,
  portfolio: {
    title: 70,
    description: 500,
    tools: 70,
    technologies: 70,
    imageCaption: 100,
    projectLink: 512
  },
  workExperience: {
    position: 255,
    company: 255,
    city: 255,
    country: 255,
    description: 500
  },
  education: {
    studies: 70,
    institution: 255,
    city: 255,
    country: 255
  },
  certifications: {
    title: 255,
    description: 500,
    skills: 100
  }
}