import {useState} from 'react'
import Input from "../../components/Input/Input"
import {signupPasswordRules} from "../../../../consts/validationRules"

const CreatePassword = ({values, errors, validate, setValues}) => {
  const [passwordView, setPasswordView] = useState(false)

  const handleChange = (fieldName, value) => setValues({...values, [fieldName]: value})

  return (
    <>
      <h2>Create a password</h2>
      <p>Please create and confirm your password</p>
      <form>
        <Input
          placeholder="Enter your password"
          name="password"
          label="Password"
          type={!passwordView ? "password" : 'text'}
          setPasswordView={setPasswordView}
          passwordView={passwordView}
          value={values?.password}
          error={errors?.password || ""}
          handleChange={(event) => {
            handleChange('password', event.target.value)
            validate(signupPasswordRules, "password")
          }}
          handleBlur={() => validate(signupPasswordRules, "password", values?.password)}
        />
        <Input
          placeholder="Confirm your password"
          name="confirm-password"
          label="Confirm password"
          type="password"
          value={values?.confirmPassword}
          error={errors?.confirmPassword || ""}
          handleChange={(event) => {
            handleChange('confirmPassword', event.target.value)
            validate(signupPasswordRules, "confirmPassword", event.target.value)
          }}
          handleBlur={() => validate(signupPasswordRules, "confirmPassword", values?.confirmPassword)}
        />
      </form>
    </>
  )
}

export default CreatePassword