import {clearAuthFail, signInWithGoogle} from "../../../../store/User/user.actions"
import {useDispatch} from "react-redux"
import google from "../../../../assets/img/auth/google.svg"
import {firebaseAnalytics} from "../../../../firebase/utils";

const GetStartedButton = props => {
  const dispatch = useDispatch()
  return (
    <button className="auth-social"
        onClick={() => {
          dispatch(clearAuthFail(null))
          dispatch(signInWithGoogle())
          firebaseAnalytics.logEvent('click_google_get_started')
        }}>
      {props.icon === 'google' && <img src={google} alt="Get started"/>} {props.title && props.title }
    </button>
  )
}

export default GetStartedButton
