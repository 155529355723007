import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';

export const middlewares  = [thunk];

const composeEnhancer =   compose

export const store = createStore(rootReducer, composeEnhancer((applyMiddleware(...middlewares))));

export default store;
