import Input from "../../components/Input/Input"
import {signupInfoRules} from "../../../../consts/validationRules"
import {clearAuthFail} from "../../../../store/User/user.actions"
import {useDispatch, useSelector} from "react-redux"

const PersonalData = ({values, errors, validate, setValues}) => {
  const mapState = ({user}) => ({globalError: user.error})

  const dispatch = useDispatch()
  const {globalError} = useSelector(mapState)

  const handleChange = (fieldName, value) => {
    if (globalError) dispatch(clearAuthFail(null))
    errors[fieldName] && validate(signupInfoRules, fieldName, value)
    setValues({...values, [fieldName]: value})
  }

  return (
    <>
      <h2>Sign up to Cadium</h2>
      <form>
        <Input
          placeholder="Enter your first name"
          name="name"
          label="First name"
          type="text"
          value={values?.name || ""}
          error={errors?.name}
          handleChange={(event) => handleChange('name', event.target.value)}
          handleBlur={() => validate(signupInfoRules, "name", values?.name)}
        />
        <Input
          placeholder="Enter your email"
          name="email"
          label="Email"
          type="text"
          value={values?.email || ""}
          error={errors?.email}
          handleChange={(event) => handleChange('email', event.target.value)}
          handleBlur={() => validate(signupInfoRules, "email", values?.email)}
        />
      </form>
    </>
  )
}

export default PersonalData