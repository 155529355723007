import websiteTypes from './website.types'

const INITIAL_STATE = {
   website: null,
   websiteExists: false,
   loading: true,
   error: null,
}

const websiteReducer = (state= INITIAL_STATE, action) => {
   switch (action.type) {
      case websiteTypes.ADD_WEBSITE_START: return {...state, error: null, loading: true}
      case websiteTypes.ADD_WEBSITE_SUCCESS: return {...state, loading: false, error: null}
      case websiteTypes.ADD_WEBSITE_FAIL: return {...state, error: action.error, loading: false}
      case websiteTypes.SET_WEBSITE_START: return {...state, error: null, loading: true}
      case websiteTypes.SET_WEBSITE_SUCCESS: return {...state, website: action.payload, loading: false, error: null}
      case websiteTypes.SET_WEBSITE_FAIL: return {...state, error: action.error, loading: false}
      case websiteTypes.GET_WEBSITE_EXIST: return {...state, websiteExists: action.payload, loading: false}
      case websiteTypes.CLEAR_WEBSITE_DATA: return {...state, website: null, websiteExists: false, loading: true, error: null}
     default: return state
   }
}

export default websiteReducer
