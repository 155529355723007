import {useState} from "react"
import '../Auth.sass'
import {Link} from "react-router-dom"
import GetStartedButton from "../components/GetStartedButton/GetStartedButton"
import {useDispatch, useSelector} from "react-redux"
import {clearAuthFail, signInWithPassword} from "../../../store/User/user.actions"
import Input from "../components/Input/Input"
import { useOutletContext } from 'react-router-dom'
import {signInRules} from "../../../consts/validationRules"
import {AuthBtn} from "../Auth"
import btnIcon from '../../../assets/img/auth/btn-icon.svg'
import {emailValidation, getErrorTypeMessage} from "../../../consts/utils"
import {firebaseAnalytics} from "../../../firebase/utils";

const SignIn = () => {
  const mapState = ({user}) => ({globalError: user.error, loading: user.loading})

  const [values, setValues, errors, validate, setErrors] = useOutletContext()
  const [passwordView, setPasswordView] = useState(false)
  const [checked, setChecked] = useState(false)
  const [loadingType, setLadingType] = useState(false)

  const {globalError, loading} = useSelector(mapState)
  const dispatch = useDispatch()

  const handleChange = (fieldName, value) => {
    if (globalError) dispatch(clearAuthFail(null))
    errors[fieldName] && validate(signInRules, fieldName, value)
    setValues({...values, [fieldName]: value})
  }

  return (
    <div className="auth-form">
      <h2>Sign in to Cadium</h2>
      <form autoComplete="off">
        <Input
          placeholder="Enter your email"
          name="email"
          label="Email"
          type="text"
          value={values?.email || ""}
          error={errors?.email}
          handleChange={(event) => handleChange('email', event.target.value)}
          handleBlur={() => validate(signInRules, "email", values?.email)}
        />
        <Input
          placeholder="Enter your password"
          name="password"
          label="Password"
          type={!passwordView ? "password" : 'text'}
          setPasswordView={setPasswordView}
          passwordView={passwordView}
          value={values?.password}
          error={errors?.password || ""}
          handleChange={(event) => handleChange('password', event.target.value)}
          handleBlur={() => validate(signInRules, "password", values?.password)}
        />
        <div className="auth-checkbox">
          <label className="checkbox-container">
            <input type="checkbox" onChange={() => setChecked(!checked)}/>
              <span className="checkmark"/>
            Remember me
          </label>
          <Link to='/forgot-password' onClick={() => {
            if (globalError) dispatch(clearAuthFail(null))
            setErrors({})
          }}>Forgot password?</Link>
        </div>
        <AuthBtn title={loading && loadingType ? 'Loading...' : "Sign in"} img={btnIcon}
          onClick={(e) =>  {
            e.preventDefault()
            setLadingType(true)
            dispatch(clearAuthFail(null))
            dispatch(signInWithPassword(values.email, values.password, checked))
            firebaseAnalytics.logEvent("click_sign_in_password")
          }}
          isDisabled={emailValidation(values.email) || !values.password?.length > 0}/>
      </form>
      {globalError && <p className="error global-error">{getErrorTypeMessage(globalError)}</p>}
      <p className="auth-line">or</p>
      <GetStartedButton primary title={loading && !loadingType ? 'Loading...' : "Sign in with Google"} icon="google"/>
      <p className="description">Don’t have an account? Please <Link to='/register'
        onClick={() => {
          if (globalError) dispatch(clearAuthFail(null))
          setErrors({})
        }}>Sign Up</Link></p>
    </div>
  )
}

export default SignIn
