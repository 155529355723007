import editIcon from "../../../assets/img/builder/edit-icon-blue.svg"
import deleteIcon from "../../../assets/img/builder/trash-icon-blue.svg"
import locationIcon from "../../../assets/img/builder/location.svg"

const Card = ({project, deleteProject, editProject, hiddenElements}) => {
  const shortenDescription = (text, len) =>
    text?.length >= len ? `${text.substring(0, len)}...` : text

  const dateString = (date) => {
    if (date) {
      return new Date(date).toLocaleDateString()
    }
  }

  return (
    <div>
      <div className={"card card-portfolio " + (hiddenElements ? '' : '') + (project?.images?.length === 0 ? ' cart-flex-between':'')}>
        {!hiddenElements ?
          <div className="left-block">
            {project?.images?.length > 0 &&
            <div className="card__img-block">
              <img src={
                project.images[0]?.imagesPreviewUrls
                  ? project.images[0]?.imagesPreviewUrls
                  : project.images[0]?.url}
                alt="cadium"
                loading="lazy"
              />
            </div>}
            <div className="card__desc-block">
              <h3 title={project?.title}>{shortenDescription(project?.title, 30)}</h3>
              <span className="date-block">{dateString(project?.startYear)} - {dateString(project?.startYear)}</span>
              <p title={project?.description}>{shortenDescription(project?.description, 150)}</p>
            </div>
          </div>
          : <div>
              {project?.title && <h3 title={project?.title}>{shortenDescription(project?.title, 30)}</h3>}
              {project?.position && <h3 title={project?.position}>{shortenDescription(project?.position, 30)}</h3>}
              {project?.studies && <h3 title={project?.studies}>{shortenDescription(project?.studies, 30)}</h3>}
              {project?.institution &&
                <div className="institution" title={project?.institution}>
                  {shortenDescription(project?.institution, 30)}
                </div>}
              {(project?.country || project?.city) && <div className="location">
                <img src={locationIcon} alt="location" width="16" height="16"/>
                <p>{project?.country},{"  "}{project?.city}</p>
              </div>}
              <span className="date-block" title={project?.company}>
                {shortenDescription(project?.company, 30)}
                {(project?.company && (project?.startYear || project?.finishYear)) && <span>{"  "}•{"  "}</span>}
                {dateString(project?.startYear)}
                {project?.finishYear && <span> - </span>}
                {dateString(project?.finishYear)}
              </span>
              {project?.description &&
                <p title={project?.description}>{shortenDescription(project?.description, 150)}</p>}
          </div>
        }
        <div className="card__icon-block">
          <button className="btn-click" type="button btn-click"
                  onClick={() => editProject()}>
          <img
            className="remove-icon-select mb-15"
            width="16.67"
            height="16.67"
            src={editIcon}
            alt="remove"
          />
          </button>
          <button className="btn-click" type="button"
                  onClick={() => deleteProject()}>
          <img
            className="remove-icon-select"
            width="15"
            height="16.67"
            src={deleteIcon}
            alt="edit"
          />
          </button>
        </div>
      </div>
      <hr className="border-line-bottom"/>
    </div>
  )
}

export default Card