import userTypes from './user.types'

const INITIAL_STATE = {
  error: null,
  loading: false,
  currentUser: null,
  resetMessage: ''
}

const userReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case userTypes.AUTH_START: return {...state, error: null, loading: true}
    case userTypes.SET_CURRENT_USER: return {...state, currentUser: action.payload, loading: false}
    case userTypes.AUTH_FAIL:
      return {...state, error: action.error.message, loading: false}
    case userTypes.CLEAR_AUTH_FAIL:
      return {...state, error: null, loading: false}
    case  userTypes.RESET_PASSWORD_MESSAGE:
      return {...state, resetMessage: 'We received a request to reset the password for your account'}
    case userTypes.ERROR_RESET_PASSWORD_MESSAGE:
      return {...state, resetMessage: ''}
    case userTypes.SEND_MESSAGE_RESET_PASSWORD: return {...state, loading: true}
    default: return state
  }
};

export default userReducer