import close from '../../assets/img/builder/close.svg'

const Modal = ({ setOpenModal, title, classType, children, ...otherProps }) => {
  const closeModal = () => {
    setOpenModal(false)
    otherProps?.setErrors({})
    if (otherProps?.setDataLanguage) {
      otherProps?.setDataLanguage(otherProps?.initialLanguage)
    }
  }
  return (
    <>
      <div className="modal-overlay"/>
      <div className="modal-wrap">
        <div className={`modal-content ${classType ? classType : 'modal-base'}`}>
          {title && <div className="modal-header d-flex"><h3>{title}</h3><img src={close} alt="close"
            onClick={() => closeModal()}/></div>}
          {children}
        </div>
      </div>
    </>
  )
}

export default Modal