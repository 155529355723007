export const languageLevels = [
  { value: "Basic", label: "Basic", description: 'I am only able to communicate in this language through written communication.' },
  { value: "Conversational", label: "Conversational", description: 'I know this language well enough to verbally discuss ptroject details with a client.' },
  { value: "Fluent", label: "Fluent", description: 'I have complete command of this language with perfect grammar.' },
  { value: "Native", label: "Native", description: 'I have complete command of this language, including breath of vocabulary, idioms, and colloquialisms' },
  { value: "Don’t share language level", label: "Don’t share language level" }
]

export const languages = [
  {
    value: "Afrikaans",
    label: "Afrikaans",
  },
  {
    value: "Albanian",
    label: "Albanian",
  },
  {
    value: "Arabic",
    label: "Arabic",
  },
  {
    value: "Armenian",
    label: "Armenian",
  },
  {
    value: "Basque",
    label: "Basque",
  },
  {
    value: "Bengali",
    label: "Bengali",
  },
  {
    value: "Bulgarian",
    label: "Bulgarian",

  },
  {
    value: "Catalan",
    label: "Catalan",
  },
  {
    value: "Cambodian",
    label: "Cambodian",
  },
  {
    value: "Chinese (Mandarin)",
    label: "Chinese (Mandarin)",
  },
  {
    value: "Croatian",
    label: "Croatian",
  },
  {
    value: "Czech",
    label: "Czech",
  },
  {
    value: "Danish",
    label: "Danish",
  },
  {
    value: "Dutch",
    label: "Dutch",
  },
  {
    value: "English",
    label: "English",
  },
  {
    value: "Estonian",
    label: "Estonian",
  },
  {
    value: "Fiji",
    label: "Fiji",
  },
  {
    value: "Finnish",
    label: "Finnish",
  },
  {
    value: "French",
    label: "French",
  },
  {
    value: "Georgian",
    label: "Georgian",
  },
  {
    value: "German",
    label: "German",
  },
  {
    value: "Greek",
    label: "Greek",
  },
  {
    value: "Gujarati",
    label: "Gujarati",
  },
  {
    value: "Hebrew",
    label: "Hebrew",
  },
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "Hungarian",
    label: "Hungarian",
  },
  {
    value: "Icelandic",
    label: "Icelandic",
  },
  {
    value: "Indonesian",
    label: "Indonesian",
  },
  {
    value: "Irish",
    label: "Irish",
  },
  {
    value: "Italian",
    label: "Italian",
  },
  {
    value: "Japanese",
    label: "Japanese",
  },
  {
    value: "Javanese",
    label: "Javanese",
  },
  {
    value: "Korean",
    label: "Korean",
  },
  {
    value: "Latin",
    label: "Latin",
  },
  {
    value: "Latvian",
    label: "Latvian",
  },
  {
    value: "Lithuanian",
    label: "Lithuanian",
  },
  {
    value: "Macedonian",
    label: "Macedonian",
  },
  {
    value: "Malay",
    label: "Malay",
  },
  {
    value: "Malayalam",
    label: "Malayalam"
  },
  {
    label: "Maltese",
    value: "Maltese",
  },
  {
    label: "Maori",
    value: "Maori",
  },
  {
    label: "Marathi",
    value: "Marathi",
  },
  {
    label: "Mongolian",
    value: "Mongolian",
  },
  {
    label: "Nepali",
    value: "Nepali",
  },
  {
    label: "Norwegian",
    value: "Norwegian",
  },
  {
    label: "Persian",
    value: "Persian",
  },
  {
    label: "Polish",
    value: "Polish",
  },
  {
    label: "Portuguese",
    value: "Portuguese",
  },
  {
    label: "Punjabi",
    value: "Punjabi",
  },
  {
    label: "Quechua",
    value: "Quechua",
  },
  {
    label: "Romanian",
    value: "Romanian",
  },
  {
    label: "Russian",
    value: "Russian",
  },
  {
    label: "Samoan",
    value: "Samoan",
  },
  {
    label: "Serbian",
    value: "Serbian",
  },
  {
    label: "Slovak",
    value: "Slovak",
  },
  {
    label: "Slovenian",
    value: "Slovenian",
  },
  {
    label: "Spanish",
    value: "Spanish",
  },
  {
    label: "Swahili",
    value: "Swahili",
  },
  {
    label: "Swedish",
    value: "Swedish",
  },
  {
    label: "Tamil",
    value: "Tamil",
  },
  {
    label: "Tatar",
    value: "Tatar",
  },
  {
    label: "Telugu",
    value: "Telugu",
  },
  {
    label: "Thai",
    value: "Thai",
  },
  {
    label: "Tibetan",
    value: "Tibetan",
  },
  {
    label: "Tonga",
    value: "Tonga",
  },
  {
    label: "Turkish",
    value: "Turkish",
  },
  {
    label: "Ukrainian",
    value: "Ukrainian",
  },
  {
    label: "Urdu",
    value: "Urdu",
  },
  {
    label: "Uzbek",
    value: "Uzbek",
  },
  {
    label: "Vietnamese",
    value: "Vietnamese",
  },
  {
    label: "Italian",
    value: "Italian",
  },
  {
    label: "Welsh",
    value: "Welsh",
  },
  {
    label: "Xhosa",
    value: "Xhosa",
  }
]