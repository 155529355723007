import '../Auth.sass'
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {clearAuthFail, errorResetPasswordMessage, resetPassword} from "../../../store/User/user.actions"
import Input from "../components/Input/Input"
import { useOutletContext } from 'react-router-dom'
import {passwordResetRules} from "../../../consts/validationRules"
import {AuthBtn} from "../Auth"
import btnIcon from '../../../assets/img/auth/btn-reset-pass.svg'
import {emailValidation} from "../../../consts/utils";

const ForgotPassword = () => {
  const [values, setValues, errors, validate] = useOutletContext()
  const mapState = ({user}) => ({
    globalError: user.error,
    resetMessage: user.resetMessage,
    loading: user.loading
  })

  const {globalError, resetMessage, loading} = useSelector(mapState)
  const dispatch = useDispatch()

  const handleChange = (fieldName, value) => {
    if (globalError) dispatch(clearAuthFail(null))
    errors[fieldName] && validate(passwordResetRules, fieldName, value)
    setValues({...values, [fieldName]: value})
  }
  return (
    <div className="auth-form forgot-password">
      <h2>Forgot password?</h2>
      <p>Please enter your email, and we will send a security code</p>
      <form>
        <Input
          placeholder="Enter your email"
          name="email"
          label="Email"
          type="text"
          value={values?.resetEmail || ""}
          error={errors?.resetEmail}
          handleChange={(event) => handleChange('resetEmail', event.target.value)}
          handleBlur={() => validate(passwordResetRules, "resetEmail", values?.resetEmail)}
        />
        <AuthBtn title={loading ? 'Sending...' : "Send"} img={btnIcon}
           onClick={(e)=> {
             e.preventDefault()
             dispatch(resetPassword(values.resetEmail))
           }}
           isDisabled={emailValidation(values.resetEmail)}/>
        {globalError && <p className="error global-error">{globalError}</p>}
        {resetMessage && <p className="global-error">{resetMessage}</p>}
      </form>
      <p className="description">Don’t have an account? Please <Link to='/register'
          onClick={() => {
            if (globalError) dispatch(clearAuthFail(null))
            if (resetMessage) dispatch(errorResetPasswordMessage())
          }}>Sign Up</Link></p>
    </div>
  )
}

export default ForgotPassword