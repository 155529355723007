import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import ReactDOM from 'react-dom'
import {BrowserRouter} from "react-router-dom"

import './assets/styles/global.sass'
import App from './App'
import {Provider} from "react-redux"
import store from './store/createStore'

ReactDOM.render(
  <HelmetProvider>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter><App /></BrowserRouter>
      </Provider>
    </React.StrictMode>
  </HelmetProvider>,
  document.getElementById('root')
)
