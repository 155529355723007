import {useEffect, useState} from "react"
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux"
import Builder from "../../components/Builder/Builder"
import Loader from "../../components/shared/Loader"
import Footer from "../../components/Builder/footer/Footer"
import Modal from "../../components/Modal/Modal"
import PersonalInformation from "../../components/Builder/steps/PersonalInformation/PersonalInformation"
import SkillsAndLanguages from "../../components/Builder/steps/SkillsAndLanguages/SkillsAndLanguages"
import Portfolio from "../../components/Builder/steps/Portfolio/Portfolio"
import WorkAndEducation from "../../components/Builder/steps/WorkAndEducation/WorkAndEducation"
import Certifications from "../../components/Builder/steps/Certifications/Certifications"
import {addDataToWebsite, clearWebsiteData, fetchWebsiteTemplate} from "../../store/Website/website.actions"
import {setCurrentUser} from "../../store/User/user.actions"
import {convertValuesToFormData, overflowHidden} from "../../consts/utils"
import {personalInfoRules, certificationRules, portfolioRules} from "../../consts/validationRules"
import {INITIAL_STATE, INPUTS_LIMIT_STATE, LIMIT_STATE} from "../../consts/state"
import {auth, firebaseAnalytics} from "../../firebase/utils"

import logoutIcon from '../../assets/img/builder/logout.svg'
import shareIcon from '../../assets/img/builder/share.svg'
import saveIcon from '../../assets/img/builder/save.svg'
import sharedIcon from '../../assets/img/builder/shared.svg'
import success from "../../assets/img/builder/success-icon.svg"
import publishIcon from '../../assets/img/builder/publish.svg'

const Dashboard = (props) => {

  const mapState = ({user, data}) => ({
    websiteExists: data.websiteExists,
    website: data.website,
    currentUser: user.currentUser,
    ready: data.website?.ready,
    loading: data.loading
  })

  const {websiteExists, website, currentUser, ready, loading} = useSelector(mapState)

  const [values, setValues] = useState(INITIAL_STATE)
  const [editedValues, setEditedValues] = useState({})

  const [limits, setLimits] = useState(LIMIT_STATE)
  const [limitInputs, setLimitInputs] = useState(INPUTS_LIMIT_STATE)

  const [errors, setErrors] = useState({})
  const [dataLoading, setDataLoading] = useState(!websiteExists && loading)

  const [copySuccess, setCopySuccess] = useState(null)
  const [modalFinish, setModalFinish] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userId = currentUser?.user?._id
  const websiteName = 'cadium.io'

  useEffect(() => {firebaseAnalytics.logEvent('dashboard_visited')}, [])
  useEffect(() => {overflowHidden(modalFinish)}, [modalFinish])

  useEffect(() => {
    if (loading && !websiteExists && userId) {
      dispatch(fetchWebsiteTemplate(userId))
    }
    if (!loading && !websiteExists && userId) {
      dataLoading && setDataLoading(false)
    }
  }, [dataLoading, dispatch, loading, userId, websiteExists])

  useEffect(() => {
    if (website && dataLoading) {
      setValues(website)
      setDataLoading(false)
    }
  }, [dataLoading, website])

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopySuccess(null)
    }, 1500)
    return () => clearTimeout(timer)
  }, [copySuccess])

  const validate = (rules, field, stateValues = values) => {
    if (!rules) return true
    let isValid = true
    const errorsObj = field ? {...errors} : {}
    const handleError = (message, fieldName) => {
      isValid = false
      errorsObj[fieldName] = message
    }
    const checkField = (rule, field, stateValues) => {
      if ((rule[field].required)
        && !stateValues?.[field]) handleError('The field is required', field)
      if ((rule[field].required || rule[field].str)
        && stateValues?.[field] && stateValues?.[field].trim() === "") handleError('Text is invalid', field)
      if (
        rule[field].email &&
        stateValues[field] &&
        !(stateValues[field]?.toLowerCase().match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ))
      ) handleError('Email is invalid', field)
    }
    if (field) {
      errorsObj[field] = null
      checkField(rules.find(rule => rule[field]), field, stateValues)
    } else {
      rules.forEach(rule => {
        const fieldName = Object.keys(rule)[0]
        checkField(rule, fieldName, stateValues)
      })
    }
    setErrors(errorsObj)
    return isValid
  }

  const valuesToUpload = (publish) => {
    const valuesToUpload = {...editedValues}
    if (!values.ready && publish) {
      valuesToUpload.ready = true
      firebaseAnalytics.logEvent("site_ready")
    }

    const formData = new FormData()

    if (valuesToUpload.avatar?.[0]) valuesToUpload.avatar = valuesToUpload.avatar[0].file

    convertValuesToFormData(valuesToUpload, formData)

    return formData
  }

  const addFieldItem = (item, field) => {
    setValues({...values, [field]: [...values[field], item]})
    setEditedValues({...editedValues, [field]: [...values[field], item]})
  }

  const updateFieldItem = (item, field) => {
    const array = [...values[field]]
    array[array.findIndex(arrayItem =>
      arrayItem._id ?
        arrayItem._id === item._id :
        arrayItem.id === item.id
    )] = item
    setValues({...values, [field]: array})
    setEditedValues({...editedValues, [field]: array})
  }

  const removeFieldItem = (index, field) => {
    const array = [...values[field]]
    array.splice(index, 1)
    setValues({...values, [field]: array})
    setEditedValues({...editedValues, [field]: array})
  }

  const steps = [
    {
      label: "Contact and Personal Information",
      content:
        <PersonalInformation
          values={values}
          limitSocialNetworks={limits.socialNetworks}
          limitInputs={limitInputs.personalInformation}
          setLimits={setLimits}
          setLimitInputs={setLimitInputs}
          setValues={(field, value) => {
            const updatedValues = {...values, [field]: value}
            const editValues = {...editedValues, [field]: value}
            field !== 'socialNetworks' && updatedValues.socialNetworks?.forEach(network => network.updated = false)
            setValues(updatedValues)
            setEditedValues(editValues)
          }}
          validate={(field, values) => validate(personalInfoRules, field, values)}
          errors={errors}
          setErrors={setErrors}
        />,
      validationRules: personalInfoRules,
    },
    {
      label: "Skills and Languages",
      content:
        <SkillsAndLanguages
          values={values}
          limitLanguages={limits.languages}
          limitSkills={limits.skills}
          limitInput={limitInputs.skillLen}
          setValues={(field, value) => {
            setValues({...values, [field]: value})
            setEditedValues({...editedValues, [field]: value})
          }}
          updateLangItem={(item) => updateFieldItem(item, 'languages')}
          addLangItem={(item) => addFieldItem(item, 'languages')}
          setErrors={setErrors}
        />
    },
    {
      label: "Work Experience and Education",
      content:
        <WorkAndEducation
          getField={(field) => values[field]}
          addFieldItem={(item, field) => addFieldItem(item, field)}
          updateFieldItem={(item, field) => updateFieldItem(item, field)}
          removeFieldItem={(index, field) => removeFieldItem(index, field)}
          validate={validate}
          limitEducation={limits.education}
          limitWorkExperience={limits.workExperience}
          limitInputsWorkExperience={limitInputs.workExperience}
          limitInputsEducation={limitInputs.education}
          errors={errors}
          setErrors={setErrors}
        />
    },
    {
      label: "Portfolio",
      content:
        <Portfolio
          portfolio={values.portfolio}
          limitsPortfolio={limits.portfolio}
          limitImages={limits.portfolioImages}
          limitInputs={limitInputs.portfolio}
          addPortfolioItem={(item) => addFieldItem(item, 'portfolio')}
          updatePortfolioItem={(item) => updateFieldItem(item, 'portfolio')}
          removePortfolioItem={(index) => removeFieldItem(index, 'portfolio')}
          validate={(field, dataInput) => validate(portfolioRules, field, dataInput)}
          validateSubmit={(rules, dataInput) => validate(portfolioRules, null, dataInput)}
          errors={errors}
          setErrors={setErrors}
        />
    },
    {
      label: "Certifications",
      content:
        <Certifications
          certifications={values?.certifications}
          skills={values?.skills}
          addFieldItem={(item) => addFieldItem(item, 'certifications')}
          updateFieldItem={(item) => updateFieldItem(item, 'certifications')}
          removeFieldItem={(index) => removeFieldItem(index, 'certifications')}
          validate={(field, dataInput) => validate(certificationRules, field, dataInput)}
          validateSubmit={(rules, dataInput) => validate(certificationRules, null, dataInput)}
          limitCertifications={limits.certifications}
          limitInputs={limitInputs.certifications}
          errors={errors}
          setErrors={setErrors}
        />
    }
  ]

  const submit = (publish, lastStep) => {
    if (Object.keys(editedValues).length || lastStep || publish)
    dispatch(addDataToWebsite(valuesToUpload(publish), userId)).then(() => setEditedValues({}))
  }

  const copyToClipBoard = async (copy) => {
    firebaseAnalytics.logEvent("click_share_button")
    try {
      await navigator.clipboard.writeText(copy)
      setCopySuccess('Copied to clipboard!')
    } catch (err) {
      setCopySuccess('Failed to copy!')
    }
  }

  return (
    dataLoading ?
      <Loader/> :
      <>
        <ul className="navigation">
          {currentUser && Object.keys(editedValues).length !== 0 && (values.fullName && values.jobTitle) && (
            <li className="link mobile-icon">
              <button onClick={() => {submit()}}>
                <span>{loading ? 'Saving...' : 'Save'}</span>
                {loading ? <span className="saving-mobile">Saving...</span> : <img src={saveIcon} alt="Save"/>}
              </button>
            </li>
          )}
          {ready && currentUser && (values.fullName && values.jobTitle) && (
            <li className="link mobile-icon">
              <button type="button"
                      onClick={() => copyToClipBoard(`https://${websiteName}/${userId}`)}>
                <span>{copySuccess ? copySuccess : 'Share website'}</span>
                <img src={copySuccess ? shareIcon : sharedIcon } alt="Share"/>
              </button>
            </li>
          )}
          {ready && currentUser && (
            <li className="link btn-click">
              <a href={`https://${websiteName}/${userId}`}
                 target={"_blank"}
                 onClick={() => firebaseAnalytics.logEvent("click_publish_website")}
                 rel="noopener noreferrer">Visit website</a>
            </li>
          )}
          {(!ready && currentUser && (values.fullName && values.jobTitle)) && (
            <li className="link mobile-icon">
              <button onClick={() => {
                submit(true);
                if (!loading) setModalFinish(true)
                firebaseAnalytics.logEvent("click_publish_website")
              }}><span>Publish website</span>
                <img src={publishIcon} alt="Publish website"/>
              </button>
            </li>
          )}
          <li className="link">
            <button type="button" onClick={() => {
              sessionStorage.removeItem('cadiumUser')
              localStorage.removeItem('cadiumUser')
              dispatch(setCurrentUser(null))
              dispatch(clearWebsiteData())
              props.setIsUserRedirect(true)
              auth.signOut().then(() => navigate('/')).catch(() => navigate('/'))
            }}>Log out <img src={logoutIcon} alt="Log out"/>
            </button>
          </li>
        </ul>
        <Builder
          values={values}
          loading={loading}
          content={steps}
          validate={rules => validate(rules)}
          submit={(e, lastStep) => submit(e, lastStep)}
          setModalFinish={setModalFinish}
        />
        <Footer/>
        {modalFinish && <Modal setOpenModal={setModalFinish} classType="success">
          <img src={success} alt="Great work!"/>
          <h3>Great work!</h3>
          <p>Your website is baked. Feel free to share it with your <br/>
            clients, use it as your CV, or any other way you want</p>
          <div className="stepper-footer form-btn-block">
            <button className="btn-transparent stepper-footer-btn"
                    onClick={() => setModalFinish(false)}>Keep editing</button>
            <a className="btn stepper-footer-btn" onClick={() => setModalFinish(false)}
                  href={`https://${websiteName}/${userId}`} target={"_blank"} rel="noopener noreferrer">Visit website</a>
          </div>
        </Modal>}
      </>
  )
}

export default Dashboard
