import {useEffect, useState} from "react"
import Compressor from "compressorjs"
import Input from "../../inputs/Input"
import TextArea from "../../inputs/TextArea"
import Modal from "../../../Modal/Modal"
import PhoneInput from 'react-phone-input-2'
import '../../phone-number-input.css'
import {instanceCountryCode} from "../../../../api/api_instance"
import {socialNetworksList} from "../../../../consts/socialNetworks"
import trashIcon from "../../../../assets/img/builder/trash-icon.svg"
import {overflowHidden} from "../../../../consts/utils"
import {firebaseAnalytics} from "../../../../firebase/utils";

const PersonalInformation = ({values, setValues, validate, errors, limitSocialNetworks, limitInputs, setErrors}) => {
  const [openModal, setOpenModal] = useState(false)
  const [countryCode, setCountryCode] = useState('us')
  const [errorImg, setErrorImg] = useState(null)

  useEffect(() => {
    !values.phoneNumber &&
      instanceCountryCode({method: "GET"})
        .then((res) => setCountryCode(res.data.country_code?.toLowerCase()))
  }, [values.phoneNumber])

  useEffect(() => {firebaseAnalytics.logEvent('personalInform_visited')}, [])
  useEffect(() => {overflowHidden(openModal)}, [openModal])

  const AddSocialNetwork = ({inputLimit}) => {
    const [network, setNetwork] = useState('')
    const [link, setLink] = useState('')
    const [activeId, setActiveId] = useState(null)
    const [click, setClick] = useState(false)

   const updateValue = (link, network) => {setNetwork(network); setLink(link)}
    return (
      <>
        <div>
          <div className="social-icons">
            {socialNetworksList.map((item, index) => (
              <button
                key={item.id}
                className={`social-btn ${activeId === item.id ? "active" : click ? "inactive" : ''}`}
                value={item.value} type="button"
                onClick={(e) => {
                  updateValue(link, e.target.value)
                  setActiveId(item.id)
                  setClick(true)
                }}>
                {item.img}
              </button>
              ))}
          </div>
          {network &&
            <>
              <p className="social-nickname">{network} nickname</p>
              <div className="social-network-input form-row">
                <span className="prefix">{network === 'Upwork' ? '~' :'@'}</span>
                <Input
                  className="form-input"
                  type="text"
                  placeholder={`Enter your ${network} ${network === 'Upwork' ? 'id' :'nickname'}`}
                  name="link"
                  value={link}
                  onChange={(event) => updateValue(event.target.value, network)}
                  inputLimit={inputLimit}
                />
              </div>
              <div className="stepper-footer form-btn-block">
                <button className="btn-transparent" type="button"
                        onClick={() => setOpenModal(!openModal)}>Cancel</button>
                <button
                  className="btn btn-continue" type="button"
                  disabled={link.length <= 0 || link.trim() === ""}
                  onClick={() =>  {
                    const socialNetworksArray = [...values.socialNetworks]
                    socialNetworksArray.push({network: network, link: link})
                    setValues('socialNetworks', socialNetworksArray)
                    setOpenModal(!openModal)
                  }}>Add network</button>
              </div>
            </>}
        </div>
      </>
    )
  }

  const handleChange = (fieldName, value) => {
    errors[fieldName] && validate(fieldName)
    setValues(fieldName, value)
  }

  const handleImageChange = (e) => {
    const acceptedFiles = e.target.files
    const imageFile = acceptedFiles[0]

    const createObjectURL = (file) => {
      if (window.webkitURL) {return window.webkitURL.createObjectURL(file)}
      else if (window.URL && window.URL.createObjectURL) {return window.URL.createObjectURL(file)}
      else {setErrorImg('Something is wrong with the file. \n Try another one')}
    }

    new Compressor(imageFile, {
      convertTypes: ['image/png', 'image/webp', 'image/heic', 'image/heif', 'image/bmp', 'image/svg', 'image/tif', 'image/tiff', 'image/gif', 'image/raw'],
      convertSize: 0,
      maxWidth: 500,
      maxHeight: 500,
      success: (results) => {
       const newImages = Array.from(acceptedFiles)?.map((file) => {
          return {file: results, imagesPreviewUrls: createObjectURL(file)}
        })
        handleChange('avatar', newImages)
        setErrorImg(null)
      },
      error(err) {
        console.log(err)
        setErrorImg('The first argument \n must be an image File')
      }
    })
  }

  return (
    <form>
      <h4 className="form-section-title">Personal Information</h4>
      <div className="block-two-row mobile center">
        <div className="avatar-img">
          {(values.avatar?.[0] || values.avatar) ? (
            <div className="pre-img">
              {Object.keys(values.avatar).length !== 0 && <img
                src={
                  values.avatar[0]?.file ?
                    values.avatar[0].imagesPreviewUrls : values.avatar && values.avatar
                }
                alt=""
                key={Date.now()}
              />}
            </div>
          ) : <div className="pre-img"/>}
          <input
            id="avatarUploader"
            type="file"
            name="avatarUploader"
            onChange={(e) => handleImageChange(e)}
            accept="image/*, .heic, .heif"
          />
          <label htmlFor="avatarUploader">
            <p>Add Image</p>
            {errorImg && <p className={`error-message error-message-img ` + (errorImg && `error`)}>{errorImg}</p>}
          </label>
        </div>
        <div>
          <Input
            placeholder="Enter your full name"
            name="fullName"
            label="Full name"
            type="text"
            value={values.fullName || ""}
            error={errors.fullName}
            handleChange={(event) => handleChange('fullName', event.target.value)}
            handleBlur={() => validate('fullName')}
            inputLimit={limitInputs.fullName}
            require="true"
          />
          <Input
            placeholder="Enter your job title"
            name="jobTitle"
            label="Job title"
            type="text"
            value={values.jobTitle}
            error={errors.jobTitle || ""}
            handleChange={(event) => handleChange('jobTitle', event.target.value)}
            handleBlur={() => validate('jobTitle')}
            inputLimit={limitInputs.jobTitle}
            require="true"
          />
        </div>
      </div>
      <hr className="border-line-bottom"/>
      <div>
        <h4 className="form-section-title">About Me</h4>
        <TextArea
          placeholder="Write about yourself"
          name="aboutMe"
          type="text"
          value={values.aboutMe || ""}
          error={errors.aboutMe}
          handleChange={(event) => handleChange('aboutMe', event.target.value)}
          handleBlur={() => validate('aboutMe')}
          inputLimit={limitInputs.aboutMe}
        />
      </div>
      <hr className="border-line-bottom"/>
      <div className="contact-block">
        <h4 className="form-section-title">Contact Information</h4>
        <div className="block-two-row mobile">
          <div>
            <Input
              placeholder="Enter your email"
              name="email"
              type="text"
              value={values.email || ""}
              error={errors.email}
              handleChange={(event) => handleChange('email', event.target.value)}
              handleBlur={() => validate('email')}
              inputLimit={limitInputs.email}
              count="true"
            />
            <Input
              placeholder="Enter Location"
              name="location"
              type="text"
              value={values.location || ""}
              error={errors.location}
              handleChange={(event) => handleChange('location', event.target.value)}
              handleBlur={() => validate('location')}
              inputLimit={limitInputs.location}
              count="true"
            />
          </div>
          <div>
            <PhoneInput
              country={countryCode}
              value={values.phoneNumber}
              onChange={(value) => handleChange('phoneNumber', value)}/>
          </div>
        </div>
      </div>
      <hr className="border-line-bottom"/>
      <>
        <div className="d-flex align-baseline justify-between">
          <h4 className="form-section-title">Social Networks (max. {limitSocialNetworks})</h4>
          <button
            className="add-btn"
            type="button"
            disabled={values?.socialNetworks?.length === limitSocialNetworks}
            onClick={() => {setOpenModal(!openModal)}}
          >
            <span>+</span> Add social network
          </button>
        </div>
        <div className="social-network-block">
          {values.socialNetworks?.map((network, index) => {
            const updateValue = (link, network) => {
              const socialNetworksArray = [...values.socialNetworks]
              socialNetworksArray[index] = {network: network, link: link}
              setValues('socialNetworks', socialNetworksArray)
            }
            return (
              <div key={index} className="social-network">
                <div className="social-network-input form-row">
                  <span className="prefix-icon">
                    {socialNetworksList.map((icon, index) =>
                      network.network === icon.value && <span key={index}>{icon.img}</span>)}
                  </span>
                  <span className="prefix">{network.network === 'Upwork' ? '~' :'@'}</span>
                  <Input
                    className="form-input"
                    type="text"
                    placeholder={network.link}
                    name="link"
                    value={network.link}
                    onChange={(event) => updateValue(event.target.value, network.network)}
                  />
                </div>
                <button type="button" className="social-network-delete btn-icon"
                  onClick={(event) => {
                    event.preventDefault()
                    const socialNetworks = [...values.socialNetworks]
                    socialNetworks.splice(index, 1)
                    setValues('socialNetworks', socialNetworks)
                  }}>
                  <img src={trashIcon} alt="delete"/>
                </button>
            </div>
            )
          })}
        </div>
      </>
      <hr className="border-line-bottom"/>
      {openModal && <Modal
        setOpenModal={setOpenModal} title="Add social network" classType="modal-small" setErrors={setErrors}>
          <AddSocialNetwork />
      </Modal>}
    </form>
  )
}

export default PersonalInformation
