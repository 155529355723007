import ImageUploader from "../ImageUploader/ImageUploader"
import Input from "../../../inputs/Input"
import trashIcon from "../../../../../assets/img/builder/trash-icon.svg"

const PortfolioImages = ({images, addImage, deleteImage, setImageCaption, errorImg, setErrorImg,
 limitImages, limitCaption}) => {
  return (
    <div className="form-row">
      <label className="label">Project images (max. {limitImages})</label>
      <div className="upload-project-img-block">
        {images.length > 0 && images.map((image, index) => (
          <div className="preview-image-block" key={index}>
            <div className="preview-image-delete btn-click" onClick={() => deleteImage(index)}>
              <img
                className="remove-icon-select"
                width="15"
                src={trashIcon}
                alt="cadium"
              />
            </div>
              <div className="preview-image">
                <img
                  src={image.imagesPreviewUrls || image.url}
                  alt={`Upload Preview ${index + 1}`}
                  className="img"
                  loading="lazy"
                />
              </div>
              <Input
                className="form-textarea form-textarea-image-desc"
                placeholder="Enter a Caption"
                type="text"
                value={image.description || ''}
                onChange={(event) => setImageCaption(index, event.target.value)}
                inputLimit={limitCaption}
              />
          </div>
        ))}
        {images.length < limitImages &&
        <ImageUploader addImage={addImage} setErrorImg={setErrorImg} errorImg={errorImg}/>}
      </div>
    </div>
  )
}

export default PortfolioImages