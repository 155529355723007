const userTypes = {
  AUTH_START: 'AUTH_START',
  AUTH_FAIL: 'AUTH_FAIL',
  CLEAR_AUTH_FAIL: 'CLEAR_AUTH_FAIL',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  RESET_PASSWORD_MESSAGE: 'RESET_PASSWORD_MESSAGE',
  ERROR_RESET_PASSWORD_MESSAGE: 'ERROR_RESET_PASSWORD_MESSAGE',
  SEND_MESSAGE_RESET_PASSWORD: 'SEND_MESSAGE_RESET_PASSWORD'
};

export default userTypes;