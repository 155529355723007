export const convertValuesToFormData = (model, formData, namespace = '') => {
  for (let propertyName in model) {
    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName
    if (model[propertyName] instanceof Date) {
      formData.append(formKey, model[propertyName])
    } else if (model[propertyName] instanceof Array) {
      if (model[propertyName].length === 0) {
        formData.append(`${propertyName}[]`, [])
      } else {
        model[propertyName].forEach((element, index) => {
          if (typeof element === 'string') formData.append(formKey, element)
          else {
            const tempFormKey = `${formKey}[${index}]`
            convertValuesToFormData(element, formData, tempFormKey)
          }
        })
      }
    } else if (typeof model[propertyName] === 'object' && !(model[propertyName])) {
      convertValuesToFormData(model[propertyName], formData, formKey)
    }
    else {
      if (model[propertyName].length === 0 && typeof model[propertyName] === 'string'
        && (model[propertyName] === 'aboutMe' || model[propertyName] === 'location')) {
        let blob = new Blob()
        formData.append(model[propertyName], blob)
      } else {
        formData.append(formKey, model[propertyName])
      }
    }
  }
}

export const overflowHidden = (openModal) => {
  if (openModal) document.body.classList.add('no-scroll')
  else document.body.classList.remove('no-scroll')
}

export const getErrorTypeMessage = (globalError) => {
  let message = ""
  if (globalError === 'EMAIL_EXISTS') {
    message = "The email address is already in use"
  }
  else if (globalError === 'EMAIL_NOT_FOUND' || globalError === 'INVALID_PASSWORD') {
    message = "The email address or password is incorrect. Please try again"
  }
  else if (globalError === 'MISSING_PASSWORD') {
    message = "Add Password end Confirm password"
  }
  else {
    message = globalError
  }
  return message
}

export const emailValidation = (text) => {
  return !(text.toLowerCase().match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
}
