import {useState, useEffect} from "react"
import Card from "../../../card/Card"
import Input from "../../../inputs/Input"
import Terms from "../../../terms/Terms"
import FormBtns from "../../../formBtns/FormBtns"
import Modal from "../../../../Modal/Modal"
import {overflowHidden} from "../../../../../consts/utils"

const Education = (
  {education, addEducationItem, removeEducationItem, updateEducationItem, errors, setErrors, validate, validateSubmit,
    limitEducation, limitInputsEducation}) => {

  const initialProject = {
    id: new Date().getUTCMilliseconds(),
    studies: '',
    institution: '',
    city: '',
    country: '',
    startMonth: '',
    startYear: '',
    finishMonth: '',
    finishYear: '',
  }

  const [dataInput, setDataInput] = useState(initialProject)
  const [openModal, setOpenModal] = useState(false)

  const handleChange = (fieldName, value) => {
    errors[fieldName] && validate(fieldName, dataInput)
    const updatedValues = {...dataInput, [fieldName]: value}
    setDataInput(updatedValues)
  }

  useEffect(() => {overflowHidden(openModal)}, [openModal])

  return (
    <div>
      <div>
        <div className="d-flex align-baseline justify-between">
          <h4 className="form-section-title">Education (max. {limitEducation})</h4>
          <button
            className="add-btn"
            type="button"
            disabled={education?.length === limitEducation}
            onClick={() => {setDataInput(initialProject); setOpenModal(!openModal)}}
          >
            <span>+</span> Add education
          </button>
        </div>
      </div>
      {education?.length > 0 &&
        education?.map((project, index) =>
          <Card
            key={index}
            project={project}
            hiddenElements={true}
            deleteProject={() => removeEducationItem(index)}
            editProject={() => {
              setOpenModal(!openModal)
              setDataInput({...project, editing: true})
            }
          }
          />
        )
      }
      {!education?.length ? <div className="border-line-bottom"/> : null}
      {openModal && <Modal title="Add the education" setOpenModal={setOpenModal} setErrors={setErrors}>
          <form onSubmit={(event) => {
            event.preventDefault()
            if (validateSubmit(dataInput)) {
              dataInput.editing ?
                updateEducationItem({...dataInput, editing: false}) :
                addEducationItem(dataInput)
              setDataInput(null)
              setOpenModal(!openModal)
            }
          }}>
            <div className="block-two-row mobile">
              <div>
                <Input
                  placeholder="Enter your institution"
                  label="Institution"
                  name="title"
                  type="text"
                  value={dataInput.institution || ""}
                  error={errors.institution}
                  handleChange={(event) => handleChange('institution', event.target.value)}
                  handleBlur={() => validate('institution', dataInput)}
                  inputLimit={limitInputsEducation.institution}
                  count="true"
                  require="true"
                />
              </div>
              <div>
                <Input
                  placeholder="Filed of study"
                  label="Enter your field of study"
                  name="title"
                  type="text"
                  value={dataInput.studies || ""}
                  error={errors.studies}
                  handleChange={(event) => handleChange('studies', event.target.value)}
                  handleBlur={() => validate('studies', dataInput)}
                  inputLimit={limitInputsEducation.studies}
                  count="true"
                  require="true"
                />
              </div>
            </div>
            <div className="border-line-bottom"/>
            <div className="block-two-row mobile">
              <div>
                <Input
                  placeholder="Enter your country"
                  label="Country"
                  name="title"
                  type="text"
                  value={dataInput.country || ""}
                  handleChange={(event) => setDataInput({...dataInput, country: event.target.value})}
                  inputLimit={limitInputsEducation.country}
                  count="true"
                />
              </div>
              <div>
                <Input
                  placeholder="Enter City"
                  label="Enter your city"
                  name="title"
                  type="text"
                  value={dataInput.city || ""}
                  handleChange={(event) => setDataInput({...dataInput, city: event.target.value})}
                  inputLimit={limitInputsEducation.city}
                  count="true"
                />
              </div>
            </div>
            <div className="border-line-bottom"/>
            <Terms setDataInput={setDataInput} dataInput={dataInput}/>
            <div className="border-line-bottom"/>
            <FormBtns
              title="Add education"
              dataInput={dataInput}
              setDataInput={setDataInput}
              setOpenModal={setOpenModal}
              isDisabled={openModal && ((!dataInput.studies || !dataInput.institution) ||
                (dataInput.studies.trim() === "" || dataInput.institution.trim() === "") ||
                (dataInput?.startYear > dataInput?.finishYear))}
            />
          </form>
      </Modal>}
    </div>
  )
}

export default Education