import {useNavigate} from "react-router-dom"
import ArrowNextIcon from "./ArrowNextIcon"
import logo from "../../assets/img/auth/logo.svg"

const NotFound = () => {
  const navigate = useNavigate()

  const GetHomePage = () => {
    return (
      <button className="auth-primary-button" onClick={() => navigate('/dashboard')}>
        Go back to home <ArrowNextIcon color='white'/>
      </button>)
  }

  return (
    <section className="auth not-found">
      <div className="auth-intro container page-not-found">
        <h1>
          <span>404</span><br/>
          Page not found
        </h1>
        <p>
          This is a 404 error, which means you've click on bad link or entered an invalid URL.<br/>
          Maybe what you are looking for can be found at homepage
        </p>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <GetHomePage />
        </div>
       <a href="/" rel="noopener noreferrer"><img className="logo" src={logo} alt="cadium.io" width="124" height="24"/></a>
      </div>
    </section>
  )
}

export default NotFound