import {useState, useEffect, Suspense} from "react"
import {Routes, Route, useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import { Helmet } from 'react-helmet-async'
import {setCurrentUser} from "./store/User/user.actions"
import Dashboard from "./pages/Dashboard/Dashboard"
import DashboardLayout from "./layouts/DashboardLayout"
import Loader from "./components/shared/Loader"
import PrivateRoute from "./components/PrivateRoute/PrivateRoute"
import NotFound from "./pages/404/404"
import Auth from "./pages/Auth/Auth"
import SignUp from "./pages/Auth/SignUp/SignUp"
import SignIn from "./pages/Auth/SignIn/SignIn"
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword"

const App = () => {

  const mapState = ({user}) => ({currentUser: user.currentUser})

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {currentUser} = useSelector(mapState)
  const [isUserRedirect, setIsUserRedirect] = useState(true)

  useEffect(() => {
    dispatch(
      setCurrentUser(JSON.parse(localStorage.getItem("cadiumUser"))
        || JSON.parse(sessionStorage.getItem("cadiumUser")))
    )
  }, [dispatch, setIsUserRedirect, isUserRedirect])

 useEffect(() => {
   if (currentUser && isUserRedirect) {
     navigate('/dashboard')
     setIsUserRedirect(false)
   }
 }, [dispatch, navigate, currentUser, isUserRedirect])

  return (
    <Suspense fallback={<Loader/>}>
        <Routes>
          <Route element={<Auth />}>
            <Route exact path='/' element={<SignIn />} />
            <Route path='/register' element={<SignUp />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
          </Route>
          <Route path="/dashboard"
             element={
               <PrivateRoute>
                 <DashboardLayout>
                   <Helmet>
                     <title>Cadium | Create a personal website easy, fast and free</title>
                     <meta
                       name="description"
                       content="The easiest and fastest way to create a personal portfolio website for free. No code, builders, or any experience is needed"
                     />
                     <meta name="keywords" content="create a website for free, create website, designed website, make website, personal website, portfolio website, resume, cv"/>
                   </Helmet>
                   <Dashboard setIsUserRedirect={setIsUserRedirect}/>
                 </DashboardLayout>
               </PrivateRoute>
             }
          />
          <Route path="*" element={<NotFound />} replace/>
        </Routes>
    </Suspense>
  )
}

export default App
