import websiteTypes from './website.types'
import {instance, instanceData} from "../../api/api_instance"

export const websiteStart = () => {
  return {type: websiteTypes.SET_WEBSITE_START}
}

export const websiteSuccess = (creator) => async dispatch => {
  await instance({url: "website", method: "GET", params: {id: creator}})
    .then((response) => {
      if (response.status === 200) {
        dispatch({type: websiteTypes.GET_WEBSITE_EXIST, payload: true})
        dispatch({type: websiteTypes.SET_WEBSITE_SUCCESS, payload: response.data.website})
      }
    }).catch((error) => {
      dispatch({type: websiteTypes.GET_WEBSITE_EXIST, payload: false})
      dispatch(websiteFail(error.message))
    })
}

export const websiteFail = (error) => {
  return {type: websiteTypes.SET_WEBSITE_FAIL, error: error}
}

export const fetchWebsiteTemplate = (creator) => async dispatch => {
  try {dispatch(websiteSuccess(creator))}
  catch (error) {dispatch(websiteFail(error))}
}

export const addWebsiteStart = () => {
  return {type: websiteTypes.ADD_WEBSITE_START}
}

export const addWebsiteFail = (error) => {
  return {type: websiteTypes.ADD_WEBSITE_FAIL, error: error}
};

export const addWebsiteSuccess = () => {
  return {type: websiteTypes.ADD_WEBSITE_SUCCESS}}

export const addDataToWebsite = (data, creator) => async dispatch => {
  dispatch(addWebsiteStart())
  await instanceData({url: "website", method: "POST", data: data})
    .then((response) => {
      if (response.status === 201) {
        dispatch(addWebsiteSuccess())
        dispatch(fetchWebsiteTemplate(creator))
      }
    })
    .catch((error) => {dispatch(addWebsiteFail(error))})
}

export const clearWebsiteData = () => {
  return {type: websiteTypes.CLEAR_WEBSITE_DATA}
}