import {useState, useEffect} from "react"
import Input from "../../inputs/Input"
import PortfolioImages from "./PortfolioImages/PortfolioImages"
import PortfolioTagsInput from "./Tag/PortfolioTagsInput"
import TextArea from "../../inputs/TextArea"
import Card from "../../card/Card"
import Terms from "../../terms/Terms"
import FormBtns from "../../formBtns/FormBtns"
import {portfolioRules} from "../../../../consts/validationRules"
import Modal from "../../../Modal/Modal"
import {overflowHidden} from "../../../../consts/utils"
import {firebaseAnalytics} from "../../../../firebase/utils";

const Portfolio = ({portfolio, addPortfolioItem, removePortfolioItem, updatePortfolioItem,
validate, errors, setErrors, validateSubmit, limitsPortfolio, limitImages, limitInputs}) => {

  const initialProject = {
    id: new Date().getUTCMilliseconds(),
    title: '',
    description: '',
    tools: [],
    technologies: [],
    startMonth: '',
    startYear: '',
    finishMonth: '',
    finishYear: '',
    images: [],
    projectLink: ''
  }

  const [dataInput, setDataInput] = useState(initialProject)
  const [openModal, setOpenModal] = useState(false)
  const [errorImg, setErrorImg] = useState(null)

  const handleChange = (fieldName, value) => {
    errors[fieldName] && validate(fieldName, dataInput)
    const updatedValues = {...dataInput, [fieldName]: value}
    setDataInput(updatedValues)
  }

  useEffect(() => {overflowHidden(openModal)}, [openModal])
  useEffect(() => {firebaseAnalytics.logEvent('portfolio_visited')}, [])

  return (
    <>
      <div>
        <div className="portfolio">
          <div className="d-flex align-baseline justify-between">
            <h4 className="form-section-title">Projects (max. {limitsPortfolio})</h4>
            <button
              className="add-btn"
              type="button"
              disabled={portfolio?.length === limitsPortfolio}
              onClick={() => {
                  setDataInput(initialProject)
                  setOpenModal(!openModal)
                  setErrorImg(null)
                }
              }
            >
              <span>+</span> Add project
            </button>
          </div>
        </div>
        {portfolio?.length > 0 &&
          portfolio?.map((project, index) =>
            <Card
              key={index}
              project={project}
              deleteProject={() => removePortfolioItem(index)}
              editProject={() => {
                setDataInput({...project, editing: true})
                setOpenModal(!openModal)
                window.scrollTo({top: 150, left: 0, behavior: "smooth"})
              }}
            />
          )
        }
        {!portfolio?.length ? <div className="border-line-bottom"/> : null}
      </div>
      {openModal && <Modal setOpenModal={setOpenModal} title="Add the project" setErrors={setErrors}>
        <form onSubmit={(event) => {
          event.preventDefault()
          if (validateSubmit(portfolioRules, dataInput)) {
            dataInput.editing ?
              updatePortfolioItem({...dataInput, editing: false}) :
              addPortfolioItem(dataInput)
            setDataInput(null)
            setErrorImg(null)
            setOpenModal(!openModal)
          }
        }}>
          <Input
            placeholder="Enter project’s name"
            label="Project Name"
            name="title"
            type="text"
            value={dataInput.title || ""}
            error={errors.title}
            handleChange={(event) => handleChange('title', event.target.value)}
            handleBlur={() => validate('title', dataInput)}
            inputLimit={limitInputs.title}
            require="true"
          />
          <div className="border-line-bottom"/>
          <PortfolioImages
            images={dataInput.images}
            limitImages={limitImages}
            limitCaption={limitInputs.imageCaption}
            errorImg={errorImg}
            setErrorImg={setErrorImg}
            addImage={value => setDataInput({...dataInput, images: [...dataInput.images, ...value]})}
            deleteImage={index => {
              const imagesArray = [...dataInput.images]
              imagesArray.splice(index, 1)
              setDataInput({...dataInput, images: imagesArray})
            }}
            setImageCaption={(index, value) => {
              const imagesArray = [...dataInput.images]
              imagesArray[index].description = value
              setDataInput({...dataInput, images: imagesArray})
            }}
          />
          <div className="border-line-bottom"/>
          <PortfolioTagsInput
            setDataInput={(value) => setDataInput({ ...dataInput, tools: value})}
            tags={dataInput.tools}
            label="Tools"
            placeholder="Enter the tools"
            limitInput={limitInputs.tools}
          />
          <div className="border-line-bottom"/>
          <PortfolioTagsInput
            setDataInput={(value) => setDataInput({ ...dataInput, technologies: value})}
            tags={dataInput.technologies}
            label="Technologies"
            placeholder="Enter the technologies"
            limitInput={limitInputs.technologies}
          />
          <div className="border-line-bottom"/>
          <Terms setDataInput={setDataInput} dataInput={dataInput} errors={errors}/>
          <div className="border-line-bottom"/>
          <Input
            placeholder="Enter project link"
            name="projectLink"
            label="Link"
            type="text"
            value={dataInput.projectLink || ""}
            handleChange={(event) => handleChange('projectLink', event.target.value)}
            inputLimit={limitInputs.projectLink}
          />
          <div className="border-line-bottom"/>
          <TextArea
            labelClassName="form-label form-label"
            id="About the project"
            className="form-textarea"
            placeholder="Write about your project"
            value={dataInput.description || ""}
            label="About the project"
            name="description"
            handleChange={(event) => handleChange('description', event.target.value)}
            inputLimit={limitInputs.description}
          />
          <FormBtns
            title="Add project"
            dataInput={dataInput}
            setDataInput={setDataInput}
            setOpenModal={setOpenModal}
            isDisabled={openModal && (
              (!dataInput.title ||  dataInput.title.trim() === "") ||
              (dataInput?.startYear > dataInput?.finishYear))}
          />
        </form>
      </Modal>}
    </>
  )
}

export default Portfolio
