import {useState, useCallback, useEffect} from "react"

const Input = ({ handleChange, handleBlur, label, labelClassName, inputLimit, error, ...otherProps }) => {
  const charLength = inputLimit || 100

  useEffect(() => setCharLeft(otherProps?.value?.length), [otherProps?.value?.length])

  const [charLeft, setCharLeft] = useState(
    otherProps?.value?.length ? otherProps?.value?.length : charLength)

  const handleKeyPress = useCallback((e) => {
    const charCount = e.target.value.length
    if (charCount <= -1) return false
    setCharLeft(charCount)
  },[])

  return (
    <div className="form-row">
      {label && <
        label className="label"
              htmlFor={`${label} ${otherProps?.labeldesc ? otherProps?.labeldesc : ''}`}>{label}
        {otherProps.require && <span className="asterisk">*</span>}</label>}
      <input
        id={`${label} ${otherProps?.labeldesc ? otherProps?.labeldesc : ''}`}
        className={`form-input ` + (error && `error-border`)}
        onChange={(e) => {
          handleKeyPress(e)
          handleChange(e)
        }}
        onBlur={handleBlur}
        maxLength={inputLimit}
        {...otherProps}
      />
      <div className="characters-left">
        <p className={`error-message ` + (error && `error`)}>{error}</p>
        {!otherProps.count &&  <span>{charLeft || 0 }/{charLength}</span>}
      </div>
    </div>
  );
}

export default Input
